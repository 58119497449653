@import '../../../styles/variables';

.agency-card {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid var(--color-300);
    transition: all var(--switch-theme-time);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    &:hover, &:focus-within {
        border: 1px solid var(--color-700);
    }

    &._hover {
        .agency-card__img img {
            transform: scale(1.1);
        }
    }

    &__trash{
        z-index: 5;
        position: absolute;
        width: 60px;
        height: 60px;
        top: 12px;
        right: 12px;
        font-size: 2em;
        color: var(--red-main);
    }

    &__img:not(.vid-image){
        height: 205px;
        min-height: 205px;
    }

    &__img{
        width: 100%;
        
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all 0.3s ease-in-out;
        }

        iframe {
            width: 100%;
            height: 100%;
        }

        &:hover, &:focus-within {
            img {
                transform: scale(1.1);
            }

            ~ .agency-card__content {
                .title_h3 {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
            }
        }
    }

    &__content {
        padding: 24px;
        background-color: var(--color-200);
        color: var(--color-main);
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
        @media(max-width: $mobile) {
            padding: 16px;
        }

        a {
            text-decoration: none;

            i{
                font-size: 1em;
                line-height: 1em;
            }

            &:hover, &:focus-within {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
            }
        }

        .button--primary {
            justify-content: center;
        }

        .title_h3 {
            transition: all var(--switch-theme-time);
            margin-bottom: 0;
        }

        p {
            line-height: 160%;
            color: var(--color-600);
        }
    }

    &__heading {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;
            color: var(--color-600);
            //> span{
            //    display: flex;
            //    flex-direction: column;
            //    gap: 8px;
            //    span:not(.rating__items){
            //        display: flex;
            //        align-items: center;
            //        gap: 4px;
            //    }
            //}
        }
    }

    &__slider {
        overflow: visible;
    }

    &__counts {
        display: flex;
        gap: 10px;
        align-items: center;

        i{
            font-size: 38px;
        }

        @media(max-width: $mobileSmall){
            gap: 8px;
        }
        > div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &__count {
        display: flex;

        > div {
            display: flex;
            flex-direction: column;
            font-size: 15px;
            @media(max-width: $mobile){
                font-size: 14px;
            }
            span {
                color: var(--color-600);
                font-size: 13px;
                @media(max-width: $mobile){
                    font-size: 12px;
                }
            }
            
        }
    }

    &__buttons {
        display: flex;
        gap: 8px;
        margin-top: auto;

        .button{
            min-height: 40px;
            min-width: 40px;
            border-radius: 20px;
        }

        .button-square {
            width: 44px;
            height: 44px;
        }
    }
}