@import "src/styles/variables";

.attention-box {
    padding: 8px 24px;
    border-radius: 4px;
    border-left: 3px solid transparent;
    background-color: var(--color-200);

    &.important {
        border-color: var(--red-800);
        .attention-box__word {
            color: var(--red-800);
        }
        p {
            display: inline;
        }
    }

    &.advice {
        background-color: transparent;
        border-left: 2px solid var(--green-main);
        color: var(--color-600);
    }

    &.warning {
        border-color: var(--gold-400);
        background-color: transparent;
        border: 1px solid;
    }

    .attention__icon {
        border-radius: 4px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 14px;
        width: max-content;
        margin-bottom: 24px;
        &.advice {
            background-color: var(--green-300);
            color: var(--green-main);
        }

        &.warning {
            background: linear-gradient(0deg, #00a480, #00a480),
            linear-gradient(
                            76.82deg,
                            #546265 11.6%,
                            #9da1a1 25.31%,
                            #828b8a 48.06%,
                            #546265 55.72%,
                            #546265 77.23%,
                            #747a7b 85.34%,
                            #546265 91.31%
            );
            color: var(--white-main);
            background-blend-mode: color, normal;

            i:after {
                background-color: var(--white-main);
            }
        }
    }
    .attention-brick{
        margin-bottom: 24px;
    }
}
