@import "./src/styles/variables.scss";
.media-upload--icon {
  width: 100px;
  border: 1px dashed var(--green-600);
  margin-top: 16px;
  margin-bottom: 16px;
}
.img-wrapper {
  background: url("/assets/img/ero-link-bg-dark.svg") no-repeat center / 100%
    205px;
}

.eroLink__input{
  &.video-link{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
} 

.agency-card__img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.vid-image{
  border-radius: 4px;
  iframe{
    border-radius: 4px;
  }
}

.thumbnail{
  pointer-events: none;
  //transform: translateY(60px);
}


.light {
  .img-wrapper {
    background: url("/assets/img/ero-link-bg-light.svg") no-repeat center / 100%
      205px;
  }
}

.create-page {
  &__form {
    &.photo {
      gap: unset;
    }

    .second-images {
      display: inline-flex;
      gap: 16px;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      padding: 16px;

      &.upload-video {
        .create-page__upload-image {
          width: 340px;
          height: 190px;

          @media (max-width: $mobileSmall) {
            width: unset;
            height: 135px;
          }
        }
      }
    }
    .tabs__nav-btn._disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    }
    .important-icons {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      transform: translateY(6px);
    }
    .girl-card__icon {
      padding-right: 4px;
      background-color: var(--green-300);
      display: inline-flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      min-height: 28px;
      min-width: 28px;
      cursor: help;
      color: var(--green-main);
    }

    .attention-box.important {
      padding: 24px;
      border-radius: 4px;
      border-left: 3px solid var(--red-main);
      background-color: var(--color-200);

      .error {
        font-size: 13px;
        display: inline-block;
        margin-right: 6px;
      }

      &__icon {
        display: inline-flex;
        gap: 6px;
        align-items: flex-end;
        margin-right: 6px;
        transform: translateY(4px);
      }
    }
  }
  &__form.photo {
    .error-block {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 20px;
      background-color: var(--red-300);
      border-radius: 4px;

      span {
        color: var(--red-main);
        display: inline-block;
      }
    }
    .upload__zone__main {
      span.text-center {
        color: var(--green-main);
        font-weight: 600;
      }
    }
    .main-images-text {
      width: 100%;
      display: flex;
    }
    .main-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      border: 1px solid var(--black-light);
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 16px;
      margin-bottom: 16px;
      .main-images--content {
        display: inline-flex;
        gap: 16px;
      }

      .text-center {
        flex-basis: 100%;
        width: min-content;
      }

      @media (max-width: $mobileSmall) {
        width: 100%;
        gap: 8px;
        padding: 8px;

        .main-images--content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .create-page__upload-image {
            width: unset;
            height: 135px;
          }
        }

        .text-center {
          order: -1;
        }
      }
    }
    .main-images ~ .second-images {
      padding: 16px;
    }

    .create-page__upload {
      &.upload-ero {
        flex-direction: column;
      }

      display: flex;
      flex-wrap: wrap;
      &.visual-hide {
        display: none;
      }

      .main-images ~ .second-images ~ .load-more {
        padding: 16px;
      }
      .load-more {
        margin-top: 16px;
        &.upload-video {
          .create-page__upload-image {
            width: 340px;
            height: 190px;
          }
        }
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;

        &.upload-video {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &-video {
        position: relative;
      }
      &-image:hover ~ .icon-bg-stories {
        visibility: visible;
        opacity: 1;
        cursor: pointer;
        background-color: var(--color-main);
      }
      &-image {
        position: relative;
        height: 208px;
        overflow: hidden;

        @media (max-width: $mobileSmall) {
          width: unset;
          height: 135px;
        }

        &.decline {
          img {
            opacity: 0.4;
          }
        }

        img {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
          border-radius:4px;
        }

        .input--upload {
          position: relative;
          display: flex;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          border: 2px dashed var(--green-600);
          width: 100%;
          height: 100%;

          &:hover {
            border-color: var(--green-main);

            input {
              pointer-events: all;
              cursor: pointer;
            }
          }
          &.drag {
            border-color: var(--green-main);
          }

          input {
            pointer-events: none;
            opacity: 0;
            width: 100%;
            height: 100%;
          }

          .icon--plus {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  &__eroLinks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: $mobileSmall) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--cont-btn {
    width: 160px;
    height: 40px;
  }
}
.eroLink {
  min-height: 110px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__input{
    width: 100% !important;
    position: absolute;
    z-index: 1;
  }
  .currentVideo {
    position: relative;
  }
}
.upload-ero__info {
  display: flex;
  flex-direction: column;

  .upload-ero__recommendation {
    display: flex;
    gap: 20px;
    width: fit-content;

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: unset;
    }

    .color-green {
      position: relative;

      .icon--info:hover {
        & + .upload-ero__watch-notification {
          display: flex;
        }
      }
    }
  }
  .upload-ero__watch {
    &-notification {
      display: none;
      position: absolute;
      left: calc(100% - 100px);
      top: calc(100% + 10px);
      width: 275px;
      padding: 12px;
      border-radius: 4px;
      z-index: 2;
      border: 1px solid var(--color-300);
      background-color: var(--black-light);
      transition: background-color var(--switch-theme-time);
      color: var(--color-700);
      text-align: center;
    }

    .icon--info {
      cursor: help;

      &:hover {
        & + span {
          display: block;
        }
      }
    }
  }
}

.icon{
  &-bg {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--red-main);
    transition: background-color 0.4s;

    .icon{
      color: var(--black-light);
    }

    &:hover {
      background-color: var(--red-500);
    }

    &-edit {
      left: 4px;
      padding: 4px;
      border-radius: 4px;
      background-color: var(--color-700);
      transition: background-color 0.4s;
      &:hover {
        background-color: var(--color-main);
      }
    
      .icon {
        color: var(--black-light);
      }
    }
    &-stories{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 4px;
      left: calc(50% - 65px);
      width: 130px;
      border-radius: 4px;
      padding: 8px 16px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s;
      color: var(--black-light);

      &:hover {
        opacity: 1;
        visibility: visible;
        background-color: var(--color-main);
      }
    
      .icon {
        color: var(--black-light);
      }
    }
  }
}
