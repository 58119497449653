@import "src/styles/variables";

.healthy-modal {
  width: 700px;
  background: var(--black-light);
  padding: 0;


  @media (max-width: $mobile) {
    width: 100%;
    padding: 0 24px 24px 24px;
  }
}
