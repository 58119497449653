@import "src/styles/variables";

.video {
  &__info__block {
    display: flex;
    gap: 60px;
    justify-content: space-between;

    &.decline {
      opacity: 40%;
    }

    @media (max-width: $tabletSmall) {
      gap: 30px;
    }
  }
}

.res {
  &__grid{
    margin: 0;
    padding: 0;
    @media (max-width: $tabletSmall) and (min-width: $mobile){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px 8px;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: minmax(312px, auto) 1fr minmax(255px, auto);
    gap: 0 64px;
    align-items: center;
    background-color: var(--color-200);
    border-radius: 24px;
    padding: 24px;

    @media (max-width: $pc) {
      gap: 0 32px;
    }

    @media (max-width: $tablet) {
      grid-template-columns: minmax(312px, auto) 1fr minmax(140px, auto);
      gap: 0 24px;
    }

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
      padding: 16px;
      align-items: unset;
      gap: 16px;
    }
  }

  &__user__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.decline {
      opacity: 40%;
    }

    @media (max-width: $mobile) {
      gap: 12px;
    }
  }

  &__user__block {
    display: flex;
    gap: 10px;
    align-items: center;
    //justify-content: space-between;
  }

  &__info__block {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $tablet) {
      gap: 16px;
    }

    &.decline {
      opacity: 40%;
    }
  }

  &__info__block__money {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    gap: 16px;
    width: 100%;
    @media(max-width: $tabletSmall){
      gap: 12px;
      align-items: center;
      align-content: center;
    }
  }

  &__user-contants{
    display: flex;
    align-items: center;
    gap: 8px;
    a{
      white-space: nowrap;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    span{
      margin: 0;
    }
  }

  &__var {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media(max-width: $tabletSmall) {
      margin-top: auto;
    }
    .rating-stars{
      height: auto;
      max-width: 100px;
    }

    &__actions {
      display: flex;
      gap: 8px;

      @media (max-width: $tablet) and (min-width: $tabletSmall){
        flex-direction: column;
      }
    }

    &__text {
      @media (max-width: $tablet) and (min-width: $tabletSmall) {
        text-align: center;
      }
    }

    &.done {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }

    &.approve {
      @media(max-width: $tabletSmall){
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
      }
      .res__status{
        flex-direction: column;
      }
    }

    &.decline{
      align-items: flex-end;
    }

    &.work {
      gap: 12px;
      align-items: normal;

      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }

    &.done {
      flex-direction: column;
      @media (max-width: $tabletSmall) {
        flex-direction: row-reverse;
        align-items: flex-start;
      }
      .color-green{
        @media (max-width: $tabletSmall) {
          margin-top: auto;
        }
      }
      .res__status{
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 280px;
        @media (max-width: $tabletSmall) {
          justify-content: flex-start;
        }
      }
      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }
  }

  &__ad {
    display: flex;
    align-items: center;
    &__photo {
      width: 86px;
      object-position: center;
      object-fit: cover;
      height: 104px;
      border-radius: 4px;
    }

    span {
      width: 86px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
}