@import "../../styles/variables";

dialog {
  display: flex;
  visibility: hidden;
  transform: scale(0);
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: inherit;
  overflow-y: auto;
  padding: 24px 12px;
  z-index: 10;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.7);
  scrollbar-gutter: stable;

  &#map{
    padding: 0px;
  }

  form.popup-form__body{
    .tariffs-top{
      display: flex;
      align-items: end;
      gap: 8px;
      margin-bottom: 12px;

      fieldset div.select button.select__button{
        top: 0px;
      }

      div{
        flex-grow: 1;
        flex-basis: 60%;
      }

      .title_h3.form-tarifs__table-title{
        border: none;
        background-color: var(--color-200);
      }
    }
  }

  @media (max-width: $mobile) {
    padding: 24px 6px 76px;
  }

  h2{
    margin-top: 32px;
    text-align: center;
  }

  &._open {
    visibility: visible;
    transform: scale(1);
  }

  .light & {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.adult {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }

  .button__close {
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover{
      background-color: transparent;
    }
  }



  form,
  > div {
    width: 100%;
    margin: auto;
    background-color: var(--black-light);
    border-radius: 25px;
    position: relative;

    a {
      &:hover {
        color: var(--color-main);
      }
    }
  }
}

.popup-form {
  &__body {
    padding: 0px 12px 48px;
    width: 360px;
    display: flex;
    flex-direction: column;
    
    &_large {
      width: 800px;
    }

    &__edit {
      width: 1000px;
    }
  }

  &__subtitle {
    display: inline-block;
    margin-bottom: 14px;
    text-align: center;
    span {
      color: var(--color-600);
    }
  }

  button[type="submit"] {
    justify-content: center;
  }

  &__text {
    font-size: 18px;
    color: var(--color-main);
    text-align: center;
    line-height: 22px;
  }

  &__error {
    color: var(--red-main);
    font-size: 15px;
    margin-top: -8px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .select-code-phone-group{
      width: 100%;
      display: grid;
      grid-template-columns: 140px 1fr;
      gap: 8px;
      fieldset {
        .select .select__button {
          top: 2px !important;
        }
        .select__body{
          max-height: 50vh;
          overflow: auto;
          .select__item{
            padding: 8px 0px 8px 4px;
            text-wrap: nowrap;
          }
        }
      }
    }
  }

  &__import {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    justify-content: space-between;
  }

  &__box {
    padding-top: 20px;
    display: flex;
    &-title {
      font-family: "Libertinus";
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: var(--color-main);
      margin-bottom: 16px;
      @media (max-width: $mobile) {
        line-height: 23px;
      }
    }
    a {
      text-decoration: none;
    }
  }

  &__fake-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px;
    min-height: 66px;
    border: 1px solid var(--color-300);
    p {
      color: var(--color-700);
    }
  }

  &__bottom-link {
    justify-content: center;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;

    span {
      font-size: 15px;
      color: var(--color-600);
    }

    button {
      font-size: 15px;
      color: var(--green-main);

      &:hover,
      &:focus-within {
        text-decoration: underline;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__scroll {
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  &__radio-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__date {
    label {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
    .dropdowndate__body {
      left: -14px;
      bottom: calc(100% - 350px);
      top: auto;
    }
  }

  &-erocontent {
    .popup-form__fake-input-erocontent {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
    }
  }

  &--operator {
    &__grid {
      &-inputs {
        display: grid;
        gap: 16px;
        @media (min-width: $mobileSmall) {
          grid-template-columns: 1fr 1fr;
        }
      }

      &-toggles {
        display: grid;
        gap: 24px;
        @media (min-width: $mobileSmall) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &__buttons {
      margin-left: auto;
      display: flex;
      gap: 12px;
      width: 100%;
      @media (min-width: $mobileExtraSmall) {
        width: 320px;
      }
    }
  }
}
