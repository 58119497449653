@import "./src/styles/variables.scss";
.filter__root ~ .create-form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}

.guide__profile {
  &-root {
    margin-top: 48px;
    margin-bottom: 48px;

    h3 + p {
      color: var(--color-main);
      font-size: 15px;
    }
  }

  &-cards {
    padding: 18px !important;
    margin-top: 24px;

    .fake-checkbox {
      margin-top: 30px;
    }
    p {
      font-size: 15px;
    }
  }
}

.profile-page {
  &__container {
    margin-bottom: 80px;
  }

  &__bottom {
    display: flex;
    margin-top: 18px;
    gap: 16px;
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
    @media (max-width: $mobileExtraSmall) {
      width: 100%;
    }
  }

  &__box {
    width: 100%;
    border-radius: 16px;
    padding: 16px 18px;
    border: 1px solid var(--color-300);
    color: var(--color-main);
    display: flex;
    align-items: center;
    @media (max-width: $mobile) {
      width: 100%;
      height: auto;
      padding: 12px;
    }
    .button {
      width: 100%;
      height: 100%
    }

    &:hover {
      cursor: pointer;
    }

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 14px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-grey {
      background-color: var(--color-400);
      &:hover {
        background-color: var(--black-light);
      }
    }

    &-green {
      background-color: var(--green-main);
      &:hover {
        background-color: var(--green-700);
      }
    }

    .checkbox {
      background: transparent;
      label,
      input {
        pointer-events: none;
      }

      label {
        color: var(--white-main) !important;
      }
    }

    span {
      display: inline-block;
      font-size: 15px;
      line-height: 20px;
    }
    & > div ~ span {
      width: 100%;
      text-align: center;
    }
    p {
      font-size: 15px;
    }
  }

  &__old-price {
    text-decoration: line-through;
    color: var(--color-500);
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

.create-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__root {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    gap: 16px;
    justify-items: flex-end;
    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
    }

    .icon--chevrone-right:after {
      content: "\6c";
      font-family: "lady4love" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      transition: background-color var(--switch-theme-time),
        color var(--switch-theme-time);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .create__select-lang {
      position: relative;
      .create-lang {
        width: 100%;
      }

      .cities__search-steady {
        label {
          padding: 12px 14px 12px 40px;
          width: 100%;
        }
      }
      &._active {
        .create__select-popup {
          opacity: 1;
          visibility: visible;
        }
        .select__arrow {
          transform: rotate(180deg);
        }
      }
      .create__select-popup {
        opacity: 0;
        visibility: hidden;
        z-index: 5;
        width: fit-content;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: absolute;
        top: 65px;
        left: 0;
        padding: 16px;
        background: var(--black-light);
        transition: all 0.3s;

        .cities__search-steady {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
          gap: 4px;

          .checkbox {
            width: 100%;
            border-radius: 4px;
            background: var(--bg-main);
            transition: background-color var(--switch-theme-time);

            .icon--check {
              left: 12px;
            }
          }
        }
        .cities__search-select {
          transition: background-color var(--switch-theme-time);
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          margin-top: 4px;
          max-height: 195px;
          width: max-content;
          overflow-y: auto;

          .checkbox {
            .icon {
              pointer-events: none;
            }
            label {
              width: 100%;
              text-align: start;
            }
          }

          .p2 {
            grid-column: span 2 / span 2;
          }
        }
      }

      @media (max-width: $mobile) {
        grid-column: span 2 / span 2;
        .create__select-popup {
          width: 100%;
          padding: 12px;
        }
        & + .create-lang--current {
          grid-column: span 2 / span 2;
        }
      }
    }
  }
}

.create-error {
  position: absolute;
  top: calc(100% + 1px);
  left: 8px;
}

.create-form__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;

  @media (max-width: $mobile) {
    justify-content: space-between;
    button{
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.create__select-lang {
  position: relative;
  .create-lang {
    width: 100%;
  }
  &._active {
    .create__select-popup {
      opacity: 1;
      visibility: visible;
    }
    .select__arrow {
      transform: rotate(180deg);
    }
  }
  .create__select-popup {
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    width: fit-content;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 65px;
    left: 0;
    padding: 16px;
    background: var(--black-light);
    transition: all 0.3s;

    .cities__search-steady {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .checkbox {
        width: 100%;
        border-radius: 4px;
        background: var(--bg-main);
        transition: background-color var(--switch-theme-time);

        .icon--check {
          left: 12px;
        }
      }
    }
    .cities__search-select {
      transition: background-color var(--switch-theme-time);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      margin-top: 4px;
      max-height: 195px;
      overflow-y: auto;

      .checkbox {
        .icon {
          pointer-events: none;
        }
        label {
          width: 100%;
          text-align: start;
        }
      }

      .p2 {
        grid-column: span 2 / span 2;
      }
    }
  }

  @media (max-width: $mobile) {
    grid-column: span 2 / span 2;
    .create__select-popup {
      width: 100%;
      padding: 12px;
    }
    & + .create-lang--current {
      grid-column: span 2 / span 2;
    }
  }
}
