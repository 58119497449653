@import "./src/styles/variables.scss";
#select-lang {
  @media (max-width: $mobile) {
    left: unset;
    right: 0;
  }
}

.create-page {
  &__main {
    display: flex;
    flex-direction: column;
    max-width: 1040px;
    width: 100%;
    @media (max-width: $tabletSmall) {
      max-width: 100%;
      margin-left: unset;
    }
    .create-page__main--title {
      font-size: 24px;
      color: var(--color-main);
      margin-bottom: 8px;
    }

    .tariffs-top{
      display: grid;
      gap: 16px;
      align-items: end;
      grid-template-columns: 1fr 150px 150px;
      margin-bottom: 16px;

      span.title_h3{
        border: none;
        background-color: var(--color-200);
      }
      
      @media (max-width: $mobile) {
        display: flex;
        flex-wrap: wrap;

        span.title_h3{
          margin-bottom: 0px;
        }
      }
      
      .select{
        .select__button{
          top: 0px;
        }
      }
    }
  }

  &__city-block {
    &._error {
      margin-bottom: 16px;
    }
    position: relative;
    grid-column: 1;

    @media (max-width: $mobile) {
      grid-column: span 2 / span 2;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px 16px;
    width: 100%;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }

    .select {
      .d-flex {
        .checkbox {
          label {
            padding: 10px 10px 10px 28px;
          }
          input {
            @media (max-width: $mobile) {
              left: 6px;
            }
          }
        }
      }
    }

    .select:nth-of-type(5) {
      grid-column: 1;
    }


    .select-countries{
      button:not(.select__item){
        margin-top: 10px !important;
        border: 1px solid var(--color-400);
        height: 40px;
      }
    }
    @media (max-width: $mobile) {
      gap: 16px 4px;
     // grid-template-columns: repeat(2, 1fr);

      
    }
    &.photo {
      display: flex;
      flex-direction: column;
    }

    .error {
      color: var(--red-800);
      font-size: 13px;
    }

    .create-nations {
      .select__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .select__search {
          grid-column: span 2 / span 2;
          margin-bottom: 16px;
        }

        .dropdown__search-select {
          width: max-content;
        }
      }
    }

    .select {
      @media (max-width: $mobile) {
        grid-column: span 3;
      }
    }

    .create-lang {
      position: relative;
      justify-content: flex-start;
      height: 60px;
      background: var(--color-300);
      transition: background-color var(--switch-theme-time);
      border-radius: 4px;
      color: var(--color-600);

      .select__arrow {
        transition: all 0.3s;
        position: absolute;
        right: 16px;
        top: 20px;
      }

      &:hover {
        background: var(--color-400);
      }
    }
    .create-lang--current {
      margin-top: 6px;
      grid-column: span 2 / span 3;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .button__close{
        position: relative;
        top: 0;
        left: 0;
      }
    }

    .select-city {
      grid-column: span 1;
      grid-column-start: 1;

      font-size: 13px;
      line-height: 16px;
      padding: 8px 16px;

      background: var(--color-300);
      transition: background-color var(--switch-theme-time);
      color: var(--color-600);
      border: none;
      border-radius: 4px;

      &:hover {
        background: var(--color-400);
      }
      .select__arrow {
        transition: all 0.3s;
        position: absolute;
        right: 16px;
        top: 20px;
      }
      .select__flag {
        width: 22px;
        height: 16px;
      }
    }

    .filter__map-input{
      grid-column: 2 / 4;
    }

    @media (max-width: $mobile) {
      .select-city,
      .search {
        grid-column: span 2 / span 2;
      }
      .header__search-input {
        padding-top: unset;
      }
    }
  }
  &__noted {
    // grid-column: span 3 / span 3;
    font-weight: 500;
    line-height: 22px;
    &-colored {
      color: var(--green-main);
    }

    // @media (max-width: $mobile) {
    //   grid-column: span 2 / span 2;
    // }
  }
  &__map {
    grid-column: span 3 / span 3;
    height: 300px;
    // @media (max-width: $mobile) {
    //   grid-column: span 2 / span 2;
    // }
  }
}

._error {
  margin-bottom: 16px;
}
.search._disabled {
  pointer-events: none;

  .filter__map-title {
    color: var(--color-400);
  }
}

