@import "./src/styles/variables.scss";
.border-right {
  border-right: 1px solid var(--color-400);
}
.short-filter__group.bottom-actions {
  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}
.registration__account {
  .ver__block__control {
    @media (max-width: $mobile) {
      .select {
        .select__button {
          padding-right: 0px;
        }
      }
    }
  }
}
.topup:not(.withdraw) {
  @media (min-width: $tabletSmall) {
    .registration__radio {
      padding: 12px 12px 0px 12px;
    }
    .registration__radio p {
      margin-top: 8px;
    }
  }
}
.registration__radio-group.topup {
  &.withdraw {
    .model__params {
      gap: 12px 16px;
    }
  }
  .registration__radio{
    flex-grow: 1;
    flex-basis: 35%;
    
    span{
      text-wrap: nowrap;
    }
  }
  @media (max-width: $tablet) {
    max-width: unset;
    min-width: unset;
    .topup-icon {
      display: none;
    }
  }
}
.topup-input__desc {
  width: 50%;
}
.form-topup {
  .ver__block__control {
    cursor: default;
  }
}

@media (max-width: $mobile) {
  .form-topup {
    .balance-page__header {
      gap: 0;
      margin-bottom: 8px;
    }
    .ver__block__control {
      background: transparent;
      flex-wrap: wrap;
      padding: 0;

      & > div {
        border-radius: 4px;
        padding: 6px;
        gap: 6px;
        border-right: none;
        background-color: var(--green-400);
      }
    }
    .model__params + div {
        flex-direction: column;
        align-items: flex-start;
    }
  }
  .topup-input__desc {
    width: 100%;
  }
  .topup-buttons {
    flex-direction: column;
  }
  .topup-pay__details {
    flex-direction: column;
  }
  .topup-facture__checkbox {
    .button {
      width: 100%;
    }
  }
}
.withdraw-inputs{
  background-color: var(--green-400);
  border-radius: 4px;
  padding: 8px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr auto 1fr;
  align-items: center;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;

    
    fieldset:nth-child(1){
      grid-area: 2 / 1 / 3 / 2;
      
    }
    fieldset:nth-child(2){
      grid-area: 1 / 1 / 2 / 2;
    }
    i{
      grid-area: 1 / 2 / 3 / 3;
    }
    fieldset:last-child{
      grid-area: 1 / 3 / 3 / 4;
    }
  }
}
.withdraw-inputs:hover{
  outline: 1px solid var(--green-accent);
}