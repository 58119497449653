@import "src/styles/variables";

.guide {
  &__button-heading {
    z-index: 4;
    position: absolute;
    top: 0;
    right: 48px;
    width: max-content;
    margin-left: auto;

    &.button.button-xs.search__icon {
      right: 8px;
      width: 35px;
      top: 18px;
    }
    &._fixed {
      position: fixed;
      top: 6px;
      &.button.button-xs.search__icon {
        top: 24px;
      }
    }

    &,
    &:hover,
    &:focus-within,
    &:active {
      background-color: var(--black-light);
    }
    @media (min-width: $mobile) {
      display: none;
      right: 16px;
    }
  }

  

  &__container {
    position: relative;
    max-width: 100%;
    h1 {
      @media (max-width: $mobile) {
        margin-top: 48px;
      }
    }

    .profile-cards {
      margin: 24px 0;
      display: flex;
      gap: 8px;
      padding: 40px;
      width: 100%;
      background-color: var(--color-200);
      border-radius: 18px;
      @media (max-width: $tablet) {
        padding: 16px;
      }
      @media (max-width: $tabletSmall) {
        flex-wrap: wrap;
      }
    }
  }

  &-page {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 130px;

    aside, div.search {
      height: max-content;
      padding: 24px;
      border-radius: 20px;
      background-color: var(--color-200);
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      span {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--color-main);
      }
       
      label.searc__label{
        padding: 4px 2px 4px 16px;
      }

      .searc__label button{
        outline: none;
      }
      @media (min-width: $mobile) {
        position: sticky;
        top: 130px;
        //top: 68px;
        min-width: 260px;
        max-width: 400px;
        margin-top: 16px;
      }

      @media (min-width: $tabletSmall) {
        min-width: 300px;
      }

      @media (max-width: $tabletSmall) {
        padding: 12px 10px;
        border-radius: 4px;
      }

      @media (max-width: $mobile) {
        width: auto;
        min-width: auto;
        background-color: var(--black-light);
        transition: all 0.1s ease-in;
        visibility: hidden;
        touch-action: none;
        pointer-events: none;
        user-select: none;
        opacity: 0;
        position: absolute;
        top: 50px;
        left: 8px;
        right: 8px;
        z-index: 4;
        &._active {
          visibility: visible;
          touch-action: auto;
          pointer-events: auto;
          user-select: auto;
          opacity: 1;
          max-height: 70vh;
          overflow-y: auto;
        }

        &._fixed {
          position: fixed;
        }
        &.search._fixed{
          position: fixed;
          top: 6px;
        }
        > .accordion {
          .accordion-button {
            padding-right: 20px;
            position: relative;
          }

          .chevrone {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      @media (max-width: $mobileSmall) {
        padding: 24px;
      }

      .accordion:last-of-type {
          border-bottom: 0;
          padding: 0;
      }
    }

    div.search{
      background-color: transparent;
      border: none;
      outline: none;
      top: 50px;
      padding: 0px;
      label{
        background-color: var(--black-light);
      }
      @media (min-width: $mobile) {
        top: 80px;
      }
    }

    .attention-box{
      margin: 24px 0;
    }
  }

  &__body {
    display: flex;
    gap: 80px;
    position: relative;
    margin-top: 24px;
    @media (max-width: $pc) {
      gap: 40px;
    }
    @media (max-width: $tabletSmall) {
      gap: 14px;
    }
    @media (max-width: $mobile) {
      position: static;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__content {
    color: var(--color-700);
    mark {
      //background-color: var(--green-100);
    }

    > h2:first-of-type {
      margin-top: 16px;
    }
    h2,
    h3 {
      margin-top: 60px;
      display: flex;
      gap: 6px;
      align-items: center;
      .guide__icon {
        background-color: var(--color-400);
      }
    }

    h2 + h3 {
      margin-top: 16px;
    }

    > img {
      margin: 24px 0;
    }
  }

  &__box {
    display: flex;
    margin: 12px 0 24px;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--color-200);

    &-img {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 14px 12px;
      background-color: var(--color-200);

      .guide__box--discount & {
        padding: 22px;
        border-radius: 4px;
        text-align: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid transparent;
        gap: 0;
        transition: all 0.15s ease-in 0s;
        @media (max-width: $mobileSmall) {
          justify-content: center;
          width: 100%;
        }

        span {
          font-size: 24px;
        }

        &:hover {
          border-color: var(--color-400);
        }
      }

      .guide__box--verify & {
        @media (max-width: $mobileSmall) {
          justify-content: center;
          width: 100%;
        }
      }
    }

    &._column {
      flex-direction: column;
      align-items: flex-start;
    }

    &--discount {
      margin-top: 60px;
      display: flex;
      background-color: transparent;
      padding: 0;
      @media (min-width: $mobile) {
        grid-template-columns: 1fr 256px;
        display: grid;
      }
      @media (max-width: $mobile) {
        flex-direction: column;
      }

      a {
        text-decoration: none;
        height: max-content;
        @media (min-width: $mobile) {
          grid-row: 1/4;
          grid-column: 2/3;
        }
      }

      h3 {
        margin-top: 0;
      }
    }

    &--verify {
      gap: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: $mobileSmall) {
        flex-direction: column;
      }
    }

    &--friend-agency {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 16px 0;

      @media (min-width: $tabletSmall) {
        content: "";
        display: table;
        clear: both;
      }

      img{
        @media(max-width: $tabletSmall){
          order: 1;
        }
        &.float-left{
          @media(min-width: $tabletSmall){
            margin-right: 10px;
          }
        }

        &.float-right{
          @media(min-width: $tabletSmall){
            margin-left: 10px;
          }
        }
      }
    }
  }

  &__flexbox {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    @media (max-width: $tabletSmall) {
      flex-direction: column;
    }
  }

  &__status {
    border-radius: 4px;
    background-color: var(--color-200);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 272px;
    height: 110px;
    @media (max-width: $mobileExtraSmall) {
      min-width: 100%;
    }
    @media (max-width: $tabletSmall) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__items {
    display: grid;
    gap: 20px;
    @media (min-width: $tabletSmall) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    background-color: var(--color-200);
    border-radius: 4px;
    padding: 20px;
    flex-direction: column;
    display: flex;
    gap: 6px;

    &-top {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        margin: -12px 0;
      }
    }

    .title_h3 {
      margin-bottom: 0;
    }
  }
  &__unilabs{
    gap: 0;
    cursor: pointer;
    max-width: 350px;
    margin: 0 auto;
  }
  &__icon {
    aspect-ratio: 1/1;
    width: 28px;
    min-width: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--color-300);

    &.green {
      background-color: var(--color-400-reverse);
    }
  }

  &__link{
    width: max-content;
    display: flex;
    margin-left: auto;
    margin-top: 8px;
  }
}
