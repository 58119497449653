@import "../../styles/variables";

.map-filter {
  &__body {
    background-color: transparent;
    position: relative;
    display: flex;
    width: 94vw;
    height: 94vh;
    padding: 0;
    padding-top: 50px;
    border: none;
    


    @media (max-width: $mobile) {
      width: 100%;
    }
    .loader-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
    }

    button{
      &.button__close{
        padding: 0;
        width: 24px;
        height: 24px;
        right: 0px;
      }
      &.gm-control-active{
        border-radius: 50% !important;
      }
    }
    

    div{
     

      .gm-style-mtc{
        button{
          border-radius: 20px !important;
        }

        ul{
          border-radius: 4px;
        }
      }
    }
  }

  &__close,
  &__natification {
    position: absolute;
    top: 40px;
  }
  &__close {
    right: 40px;
    background-color: var(--black-main);
  }

  &__natification {
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    color: var(--white-main);
    background-color: var(--black-500);
    border-radius: 4px;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
  }
  &__wrapper {
    max-width: 20vw;
    position: absolute;
    top: 10%;
    left: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: $mobile) {
      max-width: 60vw;
    }

    .girl-card{
      .girl-card__wrapper{
        .show-hover, .girl-card__expand{
          display: none;
        }
        padding-bottom: 20px;
      }
    }
    
    .clusters {
      // padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 70vh;
      overflow: auto;

      .girl-card{
        width: 100%;
        min-width: unset;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: var(--color-200);
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--color-600);
      }
    }
    > .button-icon {
      background-color: var(--white-main);
      color: var(--black-main);
      margin-left: auto;
    }

    .loader-wrapper {
      transform: translate(-50%, -50%) !important;
    }

    @media (max-width: $mobile) {
      left: 0;
    }
  }

  .girl-card {
    width: 100%;
    max-width: 360px;
    &-loader {
      height: 365px;
      width: 360px;
    }
  }
  &__circle {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--color-main-reverse);
    position: relative;
    border-radius: 50%;
    z-index: 1;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      z-index: -1;
    }
    &:before {
      background-color: var(--green-600);
      bottom: -2px;
      left: -2px;
      right: -2px;
      top: -2px;
    }
    &:after {
      width: 100%;
      height: 100%;
      background-color: var(--color-main);
    }
  }
  &__tippy {
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    position: relative;
    width: 100%;
    height: 35px;
    border-radius: 17.5px;
    background-color: grey;
    opacity: 1;
    color: var(--color-main);
    font-size: 13px;
    margin-bottom: 8px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: grey transparent transparent transparent;
      transform: rotate(0deg) translateX(-50%);
      position: absolute;
      bottom: -8px;
      left: 50%;
    }
    &--top,
    &--elite {
      color: var(--white-main);
    }

    &--elite {
      //background: linear-gradient(75.69deg, #B07D20 5.01%, #B07D20 41.48%, #FFDA44 87.96%, #FFDA44 125.92%, #B07D20 158.54%, #B07D20 207.38%);
      background: linear-gradient(
        75.69deg,
        color(display-p3 0.659 0.498 0.208) 5.01%,
        color(display-p3 0.659 0.498 0.208) 41.48%,
        color(display-p3 1 0.863 0.384) 87.96%,
        color(display-p3 1 0.863 0.384) 125.92%,
        color(display-p3 0.659 0.498 0.208) 158.54%,
        color(display-p3 0.659 0.498 0.208) 207.38%
      );
      background-blend-mode: color, normal;

      &:after {
        border-color: #ba8724 transparent transparent transparent;
      }
    }

    &--top {
      background: linear-gradient(0deg, var(--green-main), var(--green-main)),
        linear-gradient(
          76.82deg,
          #546265 11.6%,
          #9da1a1 25.31%,
          #828b8a 48.06%,
          #546265 55.72%,
          #546265 77.23%,
          #747a7b 85.34%,
          #546265 91.31%
        );
      background-blend-mode: color, normal;
      &:after {
        border-color: var(--green-main) transparent transparent transparent;
      }
    }
  }
}
.current-tippy {
  background-color: var(--black-light);
  color: var(--color-main);

  &::after {
    border-color: var(--black-light) transparent transparent transparent;
  }
}
.infoBox {
  & > img {
    display: none;
  }
}
