@import "src/styles/variables";

.stories-circle {
    grid-column: 1/5;
    grid-row: 3/4;
    margin: 40px 0;
    width: 100%;
    @media(max-width: $pc) {
        &.swiper {
            margin-right: -16px;
            padding-right: 16px;
        }
    }
    @media(max-width: $tablet) {
        grid-column: 1/4;
        grid-row: 4/5;
    }
    @media(max-width: $tabletSmall) {
        grid-column: 1/3;
        grid-row: 5/6;
    }
    @media(max-width: $mobileSmall) {
        grid-column: 1/2;
        grid-row: 9/10;
        margin-top: 28px;
        margin-bottom: 28px;
    }

    .stories-circle__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: $pc) {
            width: fit-content !important;
        }
    }
}