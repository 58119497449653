@import "./src/styles/variables";

.note {
    cursor: pointer;
    justify-content: center;
    z-index: 1;
    width: 100%;

    label {
        outline: none;
        min-height: 32px;
        width: 100%;
        //padding: 4px 10px;
        align-items: center;
        flex-direction: row-reverse;
        background-color: transparent;
        border: none;
        &:hover, &:focus-within {
            background-color: transparent;
        }
    }

    i, button {
        background-color: transparent;
        margin-left: auto;
        width: 20px;
        min-width: 20px;
    }

    span {
        @media(max-width: $mobileSmall) {
            display: none;
        }
    }
}