@import "./src/styles/variables.scss";


.statistic-table3{
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-300);
    gap: 0;
    overflow-x: auto;
    margin-bottom: 20px;
    font-size: 13px;

    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: var(--color-200);
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--color-400);
      }

    @media (max-width: $mobile) {
        font-size: 12px;
    }
    
    table{
        width: 100%;
        border-collapse: collapse;
    }

    th{
        font-weight: 600;
        color: var(--color-main);
    }
    

    th{
        &::before{
            position: absolute;
            transform: translate(-15px, -0px) ;
            content: '▼';
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }

        &:hover{
            &::before{
            opacity: 1;
        }}
    }

    
    th, td {
        padding: 8px;
        text-align: center;
        border: 1px solid var(--color-300);
        color: var(--color-main);
        
        &.col-1{
            background-color: var(--color-200);
        }
        &.col-2{
            background-color: var(--color-300);
        }
        &.col-3{
            background-color: var(--color-400);
        }
        &.income{
            background-color: var(--green-500);
        }
        &.expense{
            background-color: var(--red-500);
        }
       
    }
    .bold{
        font-weight: 600;
        background: var(--color-400);
    }
    .bold:has(.sticky) .sticky {
        font-weight: 600;
        background: var(--black-light);
    }
    
    .sticky{
        position: sticky;
        left: 0;
        background: var(--bg-main);
        z-index: 2;
    }
    
    .promoted{
        background: var(--green-700);
    }
    .elite{
        background: var(--gold-700);
    }
    .promoted.elite{
        background: linear-gradient(
            to right,
            var(--green-700) 50%,
            var(--gold-700) 50%
        );;
    }

    th:hover{
        cursor: pointer;
    }

    div.tippy.quote:hover{
        cursor: pointer;

        &::after:hover{
            cursor: help;
        }
    }
    
    tr:hover{
        cursor: default;
        background: var(--color-400);
        .sticky{
            background: var(--black-light);
        }
        .promoted{
            background: var(--green-accent);
        }
        .elite{
            background:  var(--gold-second);
        }
        .promoted.elite{
            background: linear-gradient(
                to right,
                var(--green-accent) 50%,
                var(--gold-second) 50%
            );;
        }
    }
    tr:has(th){
        background-color: var(--bg-main);
    }
    tr:has(th):hover{
        background: none;
    }
    .bold:has(.tilda) .tilda::before{
        content: '~';
    }
    
}