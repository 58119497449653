@import "src/styles/variables";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input {
  display: flex;
  padding: 4px 4px 4px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: none;
  min-height: 40px;
  font-size: 15px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  color: inherit;
  width: 100%;
}

.search {
  position: relative;
  outline: 1px solid var(--color-400);
  border-radius: 20px;

  &__label {
    position: relative;
    cursor: text;
    border-radius: 20px;
    padding: 4px 2px 4px 16px;
    min-height: 40px;
    max-height: 40px;
    background-color: var(--gray-400);
    transition: background-color var(--switch-theme-time);
    display: flex;
    align-items: center;

    &:hover,
    &:focus-within {
      background-color: var(--color-200);
    }

    
  }

  &__input {
    width: 100%;
    padding: 0 46px 0 0;
    min-height: 0;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    color: var(--color-main);
    font-size: 15px;

    &::placeholder {
      color: var(--color-600);
      transition: color var(--switch-theme-time);
    }

    @media (max-width: $mobile) {
      padding: 0 40px 0 0;
    }
  }
  @media screen and (max-width: $mobileExtraSmall) {
    min-width: 50%;
  }

  &__close, &__close-r8px {
    position: absolute;
    background-color: transparent;

    .r8px{
      right: 8px;
    }

    &:hover,
    &:focus-within {
      background-color: transparent;
    }

    @media (max-width: $mobile) {
      border-radius: 4px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__close{
    right: 45px;
    top: calc(50% - 19px);
    position: absolute;
    padding: 0px;
    width: fit-content;
    @media( max-width: $mobile ){
      top: calc(50% - 17px);
    }
    
  }
  
  &__close-r8px{
    right: 8px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    padding: 5px;
    width: 35px;
    height: 35px;
    transition: background-color 0.3s ease-in-out;
    
    &:hover{
      background-color: var(--green-500);
    }
  }
}
