@import "./src/styles/variables.scss";

footer {
  font-size: 15px;
  background-color: var(--bg-footer-main);
  position: relative;

  @media (max-width: $mobile) {
    font-size: 14px;
  }

  .logo {
    padding: 60px 0;
    padding-left: 16px;
    text-decoration: none;
    width: 280px;
    margin-right: 120px;
    display: flex;
    gap: 14px;
    flex-direction: column;
    align-items: start;

    @media (max-width: $pc) {
      margin-right: 30px;
    }

    @media (max-width: $tablet) and (min-width: $tabletSmall) {
      width: 200px;
      margin-right: 20px;
    }

    @media (max-width: $tabletSmall) {
      margin-right: 0;
    }

    @media (max-width: $tabletSmall) {
      padding: 0px 16px;
      padding-top: 40px;
    }

    img {
      width: 152px;
      max-width: 262px;
    }
    
    p {
      line-height: 120%;
      @media (max-width: $pc) {
        text-wrap: pretty;
      }
    }
  }
}

.footer {
  &__container {
    display: grid;
    padding: 0;
    grid-template-columns: auto 1fr 430px;
    @media (max-width: $tablet) {
      grid-template-columns: auto 1fr 420px;
    }
    @media (max-width: $tabletSmall) {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 36px;
    margin-top: 24px;
    position: relative;
    font-family: "Inter";
    @media (max-width: $tabletSmall) {
      max-width: 400px;
    }
    svg {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: -16px;
    }
  }
  &-categories {
    padding: 60px 0;
    @media (max-width: $tabletSmall) {
      padding: 0 16px;
    }
    &__body {
      display: grid;
      gap: 20px 56px;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(5, auto);
      grid-auto-flow: column;
      justify-content: start;
      align-items: center;
      @media (max-width: $pc) {
        gap: 20px 32px;
      }
      @media (max-width: $tablet) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(8, auto);
        align-items: center;
      }
      @media (max-width: $tabletSmall) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(5, auto);
      }
      @media (max-width: $mobileExtraSmall) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(8, auto);
        gap: 20px 40px;
      }
    }
  }

  &-info {
    padding: 60px 0;
    padding-right: 16px;
    position: relative;
    padding-left: 40px;

    background-color: var(--bg-footer-second);
    @media (max-width: $tabletSmall) {
      padding:0px 16px;
      padding-bottom: 40px;
      margin-top: 0;
      width: 100%;
    }

    
    &__top-links,
    &__bottom-links {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 28px;
      flex-wrap: wrap;
      color: var(--color-700);
      @media (max-width: $mobileExtraSmall) {
        gap: 12px;
      }
    }

    &__bottom-links {
      display: flex;
      flex-wrap: wrap;

      a {
        text-underline-offset: 6px;
        color: var(--color-500);
        &:hover,
        &:focus-within {
          color: var(--color-main);
        }
      }
    }
  }
}
