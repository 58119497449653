@import "./src/styles/variables.scss";
.create-page__form.tarifs {
  display: flex;
  flex-direction: column;
  gap: 28px;

  .button_outline--green-accent {
    width: 200px;
  }
}
.form-tarifs {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    color: var(--color-main);
    margin-bottom: 24px;
  }

  &__outtitle {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__daysroot {
    display: flex;
    margin-top: 16px;
    gap: 24px;
    flex-wrap: nowrap;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__closeTrash {
    display: none;
    @media (max-width: $mobile) {
      display: inline;
    }
  }

  &__table-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-main);
    border-radius: 4px;
    border: 1px solid var(--color-400);
    height: 100%;
    padding: 16px 12px;
    @media (max-width: $mobile) {
      min-width: 75px;
    }
  }

  &__table {
    &-head {
      display: grid;
      grid-template-columns: 136px repeat(3, 1fr) 52px;
      gap: 8px;

      .checkbox {
        background: var(--green-300);
        .icon {
          pointer-events: none;
        }

        &.checkbox_checked {
          .icon {
            border: 1px solid var(--color-main);
            background: var(--color-main);
            &::after {
              background-color: var(--color-main-reverse);
            }
          }

          label {
            color: var(--color-main) !important;
            background-color: var(--green-600) !important;
          }
        }
        &.checkbox_checked,
        &:hover {
          background: var(--green-600);
        }

        &:nth-of-type(1) {
          .icon {
            left: calc(50% - 36px);
          }
        }
        &:nth-of-type(2) {
          .icon {
            left: calc(50% - 36px);
          }
        }
        &:nth-of-type(3) {
          .icon {
            left: calc(50% - 50px);
          }
          @media (max-width: $mobile) {
            .icon {
              top: 4px;
            }
            label {
              max-width: 100px;
            }
          }
        }

        @media (max-width: $mobile) {
          .icon {
            top: 10px;
            left: 50% !important;
            transform: translateX(-50%);
          }
        }
        label {
          width: 100%;
          text-align: center;
          @media(min-width: $mobileSmall){
            padding-left: 36px;
          }

          @media(max-width: $mobileSmall){
            padding: 36px 8px 20px;
            justify-content: center;
          }

          span {
            display: block;
          }

          @media (max-width: $mobile) {
          }
        }

        input{
          @media(max-width: $mobileSmall){
            top: 18px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      @media (max-width: $mobile) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__worktimes {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;

    .form-tarifs__title {
      margin-bottom: 0px;
    }

    &--days {
      // display: flex;
      // flex-direction: column;
      // gap: 24px;

      display: grid;
      grid-template-columns: auto 1fr 1fr auto;

      & ~ .form-tarifs__root {
        .form-tarifs__title {
          height: 32px;
          display: flex;
          align-items: center;
        }
      }

      &-head {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        .checkbox {
          input {
            pointer-events: none;
          }
        }
      }
      &-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &-item {
        // display: flex;

        gap: 12px;
        display: grid;
        grid-template-columns: 32px 1fr 1fr auto;
        align-items: center;
        .input-label.error {
          border: none;
          outline: 1px solid var(--red-800);
          font-size: 13px;
          input {
            font-size: 15px;
          }
        }

        div.checkbox{
          &:hover{
            background-color: transparent;
          }
          input {
            top: 60%;
          &~label {
            background-color: var(--color-300);
            margin-top: 10px;
            min-height: 40px;
            display: flex;
            align-items: center;
            gap: 8px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .icon--check {
            pointer-events: none;
          }
          .icon--time-plan {
            &::after {
              background-color: var(--green-main);
            }
          }
        }}
      }
    }

    @media (max-width: $tabletSmall) {
      flex-direction: column;
    }
  }

  &__discounts {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--color-400);
    gap: 16px;

    .form-tarifs__title {
      span {
        margin-left: 6px;
        color: var(--green-main);
      }
    }

    &-ofday {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
.tarifs-row {
  display: grid;
  grid-template-columns: 136px repeat(3, 1fr) 52px;
  gap: 8px;

  .select{
    width: 100%;
    margin-top: 4px;
    
    .select__button{
      min-height: fit-content;
      top: 6px;
      text-wrap: nowrap;
    }
    &__arrow{
      @media(max-width: $mobileSmall){
        width: 16px;
        height: 16px;
        font-size: 14px;
      }
    }
  }
  

  .input-label.disabled {
    pointer-events: all;

    div.input-price input{
      color: var(--color-400);
    }

    div.input-price:after{
        color: var(--color-400) !important;
    }

    
  }

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, 1fr);
    .select-city {
      grid-column: 1;
    }
    .tarifs-row-trash {
      grid-column: 4;
    }
  }

  .input-label {
    flex-grow: 1;
  }

  & > span {
    font-size: 15px;
    color: var(--color-main);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    border-radius: 4px;
    width: 136px;
    background: var(--black-light);
    transition: background-color var(--switch-theme-time);
  }
  .tarifs-row-trash {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 10px;

  

    &:hover {
      .icon--trash {
        &::after {
          background-color: var(--red-main);
        }
      }
    }

    .icon--trash {
      &::after {
        background-color: var(--red-600);
      }
    }
  }
}
.tarifs-row-trash.disabled {
  opacity: 0.4;
}

.icon--time-plan{
  font-size: 24px;
}