@import "./src/styles/variables.scss";

.account-page {
    &__root {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        @media(min-width: $tabletSmall){
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 12px;
        }

        .agency-logo-banner{
            div:first-of-type{
                h3{
                    margin-bottom: 22px;
                }
            }
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        max-width: 810px;
        gap: 24px;
        @media (max-width: $tabletSmall) {
            max-width: 100%;
        }
    }
    

    &__identity {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__form {
            display: grid;
            grid-template-columns: 1fr 1fr auto;
            gap: 16px;
            align-items: center;
            @media (max-width: $mobile) {
                grid-template-columns: 1fr 160px;
                justify-content: space-between;
            }
        }
    }

    &__toggles {
        display: grid;
        grid-template-columns: auto 1fr;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .account-page__toggles-wrapper &{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: unset;
        }

        @media (max-width: $mobile) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            @media (max-width: $mobileSmall) {
                grid-template-columns: 1fr;
            }
        }
    }

    &__activate {
        display: grid;
        grid-template-columns: auto 1fr 1fr;
        align-items: center;
        gap: 16px;
        h3{
            margin: 0;
        }
        @media (max-width: $mobile) {
            gap: 8px;
            width: 100%;
        }
        .account-page__toggles-wrapper &{
            display: grid;
            grid-template-columns: max-content max-content 1fr;
            width: 100%;
            @media (min-width: $mobileSmall) {
                height: 60px;
            }
        }
    }

    &__link-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        width: 100%;
        background-color: var(--color-200);
        line-height: 28px;
        border-radius: 4px;
        color: var(--color-main);
        gap: 8px;
        min-height: 84px;
        font-size: 24px;

        &:hover {
            outline: 1px solid var(--green-main);
        }
    }

    .light & {
        background-color: rgba(31, 33, 33, 0.06);
        color: rgba(31, 33, 33, 1);
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .tariffs-top{
            display: grid;
            gap: 16px;
            align-items: end;
            grid-template-columns: 1fr 150px 150px;
            margin-bottom: 16px;
            
            @media (max-width: $mobile) {
              display: flex;
              flex-wrap: wrap;

              span.title_h3{
                margin-bottom: 0px;
              }
            }

            .select{
              .select__button{
                top: 0px;
              }
            }
        }
    }

    &__actions-page {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;
    }

    &__confirm {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $mobile) {
            flex-direction: column;
        }
    }
}
