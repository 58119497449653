.popup-order{
    &__address{
        min-height: 60px;
        justify-content: center;
    }
    .input-label__radio{
        padding: 12px 6px;
        min-width: auto;
        flex-grow: 1;
        flex-basis: 0;
    }
}