@import "./src/styles/variables.scss";

.range {
  width: 244px;
  color: var(--color-main);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__default {
    transition: 0.2s ease 0s;
    color: var(--color-600);
    padding: 0px !important;
    width: fit-content !important;

    &:hover,
    &:focus-within {
      color: var(--color-800);
      text-decoration: underline;
    }
  }

  &__input {
    padding: 0 4px;
    height: 12px;
    background-color: transparent;
    position: relative;
    margin-bottom: 8px;
    border: none;
    box-shadow: none;
    justify-content: center;
    &.equal {
      .bar-inner {
        min-width: 100%;
      }
    }

    &:not(.disabled) ~ .range__inputs {
      .range__label {
        &:hover,
        &:focus-within {
          background-color: var(--color-400);
        }
        &:active {
          background-color: var(--color-400);
        }
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.3;
      border: none;
      box-shadow: none;
      user-select: none;
      .bar,
      .thumb::before {
        cursor: default;
      }
    }
  }

  &__title {
    font-size: 15px;
    color: var(--color-main);

    span {
      color: var(--color-600);
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      position: relative;
      padding: 8px 12px;
      border-radius: 4px;
      color: var(--color-800);
      background-color: var(--color-300);
      display: flex;
      flex-wrap: nowrap;
      font-size: 15px;
      transition: all 0.2s ease 0s;
      outline: none;
      cursor: text;
      color: transparent;
      overflow: hidden;
      width: 88px;
      height: 32px;

      &:after {
        display: inline-block;
        margin-left: 4px;
      }

      &._active {
        input {
          color: var(--color-800);
        }

        &:after {
          color: var(--color-800);
        }
      }
    }

    input {
      padding: inherit;
      color: var(--color-800);
      height: 32px;
      width: 88px;

      appearance: none;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: transparent;
      position: absolute;
      outline: none;
    }
  }

  &--price {
    label:after {
      color: var(--color-600);
    }
  }

  &--weight {
    label:after {
      content: "";
    }
  }

  &--height {
    label:after {
      content: "см";
    }
  }

  .thumb {
    background-color: var(--color-main);


    &.thumb-right:before {
        @media (max-width: $mobile) {
          transform: translate(-4px, -2px);
        }
    }
    &:before {
      cursor: e-resize;
      width: 12px;
      height: 12px;
      border: none;
      box-shadow: none;
      margin: -5px -5px;

      @media (max-width: $mobile) {
        width: 16px;
        height: 16px;
        transform: translateY(-2px);
      }
    }

    .caption {
      display: none !important;
      bottom: 24px;
      width: 0;
      height: 0;
      left: -4px;

      * {
        background-color: var(--color-main);
        color: var(--black-main);
        border-radius: 50%;
        line-height: 1;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .bar {
    cursor: pointer;
  }

  .bar-inner {
    background: var(--color-main);
    border: none;
    box-shadow: none;
  }

  .bar-left,
  .bar-right {
    background-color: var(--color-500);
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 1px;
  }
}
