@import "src/styles/variables";

.icon-menu {
  display: none;
  width: 40px;
  height: 40px;
  @media (max-width: $tablet) {
    display: block;
    border: 1px solid var(--color-400);
    position: relative;
    cursor: pointer;
    z-index: 4;
    transition: all 0.2s ease 0s;
    width: 32px;
    height: 32px;
    margin-left: auto;

    &:hover,
    &:focus-within {
      background-color: var(--color-200);
    }

    span,
    &::before,
    &::after {
      content: "";
      transition: all 0.2s ease 0s;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      width: 16px;
      position: absolute;
      height: 1px;
      background-color: var(--color-main);
    }

    &::before {
      transform: translate(-50%, 6px);
      @media (max-width: $mobile) {
        transform: translate(-50%, 4px);
      }
    }

    &::after {
      transform: translate(-50%, -6px);
      @media (max-width: $mobile) {
        transform: translate(-50%, -5px);
      }
    }

    span {
      top: 50%;
      transform: rotate(0deg) translate(-50%, -50%);
    }

    &._active {
      z-index: 3;
      span {
        width: 0;
      }

      &::before {
        top: auto;
        transform: rotate(45deg) translate(-6px, 5px);
      }

      &::after {
        bottom: auto;
        transform: rotate(-45deg) translate(-5px, -6px);
      }
    }
  }
  @media (max-width: $mobile) {
    min-width: 32px;
    width: 32px;
    min-height: 32px;
    height: 32px;
  }
}
