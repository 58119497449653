@import "./src/styles/variables";

.short-filter {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  bottom: 0;
  max-width: 600px;
  overflow: clip;
  background-color: var(--bg-main);
  z-index: 4;
  padding: 72px 0 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  transition: all var(--switch-theme-time);
  @media (min-height: $mobile) {
    overflow-y: auto;
  }
  @media (min-width: $tablet) {
    display: none;
  }
  &._active {
    left: 0;
  }

  .filter__close {
    right: 20px;
    position: absolute;
    top: 16px;
    @media (max-width: $mobile) {
      top: 30px;
    }
    @media (max-width: $mobileExtraSmall) {
      top: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 0 20px;
  }

  &__group {
    display: grid;
    gap: 12px 16px;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: $mobileSmall) {
      grid-template-columns: repeat(2, auto);
    }

    .invoice-email {
      grid-column: span 2/ span 2;
    }

    .checkbox-button{
      width: 100%;
      display: flex;

      @media (max-width: 767.98px) {
          height: 36px;
      }

      label, a{
        font-size: 15px;
        padding: 0px;
        margin: auto;
        
      }
      a{
        width: 100%;
        margin: 0px;
        &:hover{
          background-color: transparent !important;
        }
      }
      label{
        padding: 14px 25px;
      }
    }
    
  }

  &__button {
    justify-content: center;
    padding: 10px 0;
    background-color: var(--green-main);
  }

  &__box {
    background-color: #0a0a0a;
    padding: 18px 10px;
    margin-top: auto;

    .light & {
      background-color: var(--color-300);
    }

    .button {
      width: 100%;
      justify-content: center;
    }
  }
}