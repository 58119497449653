.attention-brick {
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 14px;
    width: max-content;
    background-color: var(--green-300);
    color: var(--green-main);

    &--warning {
        i {
            &:before {
                border-radius: 50%;
                border: 1px solid var(--green-main);
                padding: 2px;
                font-size: 20px;
            }
        }
    }
}