@import "./src/styles/variables";

.showmore-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--white-main);
  visibility: hidden;
  opacity: 0;
  z-index: 4;
  transition: all 0.1s ease-in 0s;
  backdrop-filter: blur(2px) saturate(50%);

  
  visibility: visible;
  opacity: 1;
  
}