@import "src/styles/variables";

.connectad {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 12px;
  align-items: center;

  .connectad__day{
    padding: 0 12px;
    row-gap: 32px;
    .input-label{
      padding: 4px;
      button{
        padding: 0px;
        min-height: 32px;
        min-width: 32px;
      }
    }
  }

  &__day {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 8px;
    justify-content: space-between;
    padding: 12px 12px 0px 12px;
    align-items: center;
    //border: 1px solid var(--color-300);
    border-radius: 12px;
    width: 100%;

    &.low {
      border: 1px solid var(--red-main);
    }
  }

  &__error {
    text-align: center;
      color: var(--red-main) !important;

    &.low {
      visibility: hidden;
      height: 0;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;

    &__check {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .checkbox{
        margin-top: 10px;
        label{
          min-height: 40px;
        }
      }
    }
  }

  & ._empty-date{
    .input-label{
      input{
        display: none;
      }
    }
  }
}

.up-table {
  &__body {
    max-height: 41vh;
    overflow-y: auto;
  }

  &__header {
    font-family: "Libertinus";
    display: flex;
    border-radius: 4px 4px 0 0;
    font-size: 18px;
    background-color: var(--color-200);
    margin: 0 2px 1px 2px;
    
    th:not(:last-child){
      border-right: 1px solid var(--black-light);
    }

    td,th,span
    div {
      padding: 0 4px;
      min-height: 56px;
      align-items: center;
      justify-content: center;

      display: flex;
      width: 100%;
      color: var(--color-main);

      &:nth-of-type(1) {
        min-width: 70px;
        width: 70px;
      }
      &:nth-of-type(2) {
        min-width: 70px;
        width: 70px;
      }
      &:nth-of-type(3) {
        width: 100%;
      }
    }
  }

  &__row {
    display: flex;
    margin: 2px;

    .tippy {
      display: flex !important;
    }

    td,span
    div {
      padding: 0 8px;
      min-height: 56px;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      color: var(--color-main);

      &:nth-of-type(1) {
        min-width: 70px;
        width: 70px;
        color: var(--green-main);
      }
      &:nth-of-type(2) {
        min-width: 70px;
        width: 70px;
      }
      &:nth-of-type(3) {
        width: 100%;
        ::after{
          background-color: transparent;
        }
      }
    }
  }

  &__row {
    cursor: pointer;
    overflow: hidden;
    margin: 2px;

    &.empty{
      td{
        &:nth-of-type(3) {
          &::after{
            background-color: transparent;
          }
        }
      }
    }

    &:hover {
      outline: 1px solid var(--green-main);
      border-radius: 4px;
    }

    &:nth-child(even) td,span,
    div {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: var(--color-100);
      }
    }

    &:nth-child(odd) td,span,
    div {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: var(--color-200);
      }
    }

    td:not(:last-child){
      border-right: 1px solid var(--black-light);
    }

    td,span,
    div {
      &:nth-of-type(2) {
        font-family: "Libertinus";
        font-size: 18px;
      }

      &:nth-of-type(3) {
        justify-content: flex-start;
        position: relative;
        z-index: 1;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          background-color: var(--green-600);
          width: var(--percent);
          z-index: -1;
        }
      }
    }

    &__current {
      cursor: default;
      outline: 0;
      pointer-events: none;

      td,span,
      div {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          color: var(--color-main);
          font-family: "Libertinus";
          font-size: 18px;
        }

        &:nth-of-type(3) {
          &:after {
            content: none;
          }
        }
      }
    }

    &__zero {
      cursor: default;
      pointer-events: none;

      td,span,
      div {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          background-color: var(--color-400) !important;
        }

        &:nth-of-type(3) {
          &:after {
            content: none;
          }
        }
      }
    }

    &__zero__last {
      cursor: default;
      pointer-events: none;
      border-radius: 0 0 12px 12px;

      td,span,
      div {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          background-color: var(--color-400) !important;
        }

        &:nth-of-type(3) {
          &:after {
            content: none;
          }
        }
      }
    }

    &__last {
      cursor: pointer;
      border-radius: 0 0 12px 12px;

      td,span,
      div {
        &:nth-of-type(1) {
          color: var(--green-main);
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          color: var(--color-main);
        }

        &:nth-of-type(3) {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}


