@import "src/styles/variables";
.dropdown {
  position: relative;
  line-height: 120%; /* 15.6px */
  
  button, .button{

    &:not(.rdp-button){width: 100%;}

    transition: all 0.3s ease-in-out;
    font-size: 15px;

    @media (max-width: $mobile) {
      font-size: 14px;
    }

    & img {
      width: 28px;
      height: 20px;
    }

    & > i {
      transition: all var(--switch-theme-time);
    }

    &_no-fill {
      background-color: transparent;
      border-radius: 4px;
      font-size: 13px;
      min-height: auto;
      & > i {
        width: 12px;
        height: 12px;
      }
      &:hover,
      &:focus-within {
        background-color:transparent;
      }
    }

    &_outline {
      border: 1px solid var(--black-light);
      background-color: transparent;
      transition: all 0.2s ease 0s;
      &:hover,
      &:focus-within {
        border: 1px solid var(--dropdown-bg-hover);
      }
    }

    &_secondary {
      background-color: var(--color-400);
      color: var(--color-main);

      &:hover,
      &:focus-within {
        background-color: var(--black-light);
      }
    }

    &-square{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      &:hover{
        background-color: var(--color-200);
      }
    }

  }
  

  &__body {
    position: absolute;
    visibility: hidden;
    display: flex;
    width: max-content;
    background-color: var(--black-light);
    border-radius: 4px;
    flex-direction: column;
    gap: 4px;
    left: 0;
    padding: 6px;
    top: calc(100% + 4px);
    opacity: 0;
    z-index: 4;
    transition: all 0.3s;
    box-shadow: var(--box-shadow-main);
    > a,
    > button {
      transition: background-color 0.2s ease 0s;
      min-width: 110px;

      &:hover:not(.hover-line){
        background-color: var(--color-200);
      }
    }

    .checkbox-button{
      width: 100%;
    }

    .checkbox{
      padding: 8px;
      label{
        padding: 0 8px 0 28px
      }

      input:checked ~ label{
        background-color: transparent;
        color: var(--color-main);
        &:hover{
          background-color: transparent;
        }
      }

      input:checked{
        background-color: var(--color-main);

        &:after{
          background-color:  var(--black-light);
        }
        
      }

      &:hover{
        color: var(--color-500);
      }

      a{
        label{
          display: block;
          text-align: center;
          color: var(--color-main);
          background: transparent;
          padding: 0;
        }
      }
    }

    .language {
      min-width: auto;
      justify-content: flex-start;
    }
  }
  &__body.true {
    width: 100%;
    position: absolute;
    visibility: hidden;
    background-color: var(--black-light);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    left: 0;
    padding: 12px;
    top: calc(100% + 4px);
    opacity: 0;
    z-index: 3;
    transition: all 0.3s;
    box-shadow: var(--box-shadow-main);
    > a,
    > button{
      transition: background-color 0.2s ease 0s;
      min-width: 110px;
    }
    

    .language {
      min-width: auto;
      justify-content: flex-start;
    }
  }

  &__wrapper {
    position: relative;
  }
}

.dropdown_active {
  button {
    
    > .chevrone {
      transform: rotate(180deg);
    }
  }
  .dropdown__body {
    visibility: visible;
    opacity: 1;
  }
  .dropdown__body.true {
    width: 100%;
    visibility: visible;
    opacity: 1;
  }
}


