@import "src/styles/variables";

.tariffs-table {
  color: var(--color-main);
  @media (max-width: $mobile) {
    position: relative;
  }

  .tippy {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    display: inherit !important;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 616px 1fr;
    grid-column-gap: 12px;

    @media (max-width: $pc) {
      grid-template-columns: 1fr 690px;
    }
    @media (max-width: $tabletSmall) {
      grid-template-columns: 1fr 514px;
    }

    @media (max-width: $mobile) {
      grid-template-columns: 1fr minmax(85px, max-content);
      grid-column-gap: 0;
    }
  }

  &__header {
    align-items: center;

    .tariffs-table__row {
      @media (max-width: $mobile) {
        grid-column: 1 / 3;
        display: flex;
        gap: 10px;
      }

      & > button {
        display: flex;
        align-items: center;
        min-height: 73px;
        justify-content: center;
        border-radius: 4px 4px 0 0;
        font-family: "Libertinus";
        font-weight: 400;
        font-size: 24px;
        position: relative;

        &:before,
        &:after {
          content: "";
          position: absolute;
          transition: opacity 0.1s ease-in 0s;
        }

        &:before {
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: transparent;
        }

        &:after {
          top: 10px;
          left: 10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid var(--color-500);
          background-size: 20px;

          @media (max-width: $tabletSmall) {
            top: 6px;
            left: 6px;
            width: 20px;
            height: 20px;
            background-size: 14px;
          }

          @media (max-width: $mobile) {
            width: 16px;
            height: 16px;
            top: 4px;
            right: 4px;
            background-size: 10px;
          }
        }

        &._active:after {
          background-image: url("/assets/img/icons/check.svg");
          background-repeat: no-repeat;
          background-position: center center;
          border-color: var(--green-main);
          background-color: var(--green-main);
        }

        @media (min-width: $mobile) {
          border-bottom: 0;
          transition: all 0.15s ease-in 0s;
          cursor: default;
        }

        @media (max-width: $mobile) {
          font-size: 18px;
          min-height: 52px;
          border-radius: 4px 4px 0 0;
          width: 100%;
          opacity: 0.6;
        }

        &:nth-of-type(1){
          @media(max-width: $mobile){
            [data-active-tariff="1"] & {
              opacity: 1;
              &:before{
                background-color: var(--green-main);
              }
            }
          }
        }

        &:nth-of-type(2){
          [data-active-tariff="2"] & {
            opacity: 1;
            @media(max-width: $mobile){
              &:before{
                background-color: var(--green-main);
              }
            }
          }
        }

        &:nth-of-type(3){
          [data-active-tariff="3"] & {
            opacity: 1;
            @media(max-width: $mobile){
              &:before{
                background-color: var(--green-main);
              }
            }
          }
        }
      }
    }

    .tariffs-table__heading {
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__heading {
    font-size: 24px;
    line-height: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--bg-main);
    transition: background-color var(--switch-theme-time);
    @media (max-width: $mobile) {
      font-size: 18px;
    }
  }

  &__body,
  &__header {
    width: 100%;
  }

  &__body {
    .tariffs-table__wrapper {
      &:nth-of-type(odd) {
        .tariffs-table__title,
        .tariffs-table__row div {
          background-color: var(--color-100);

          @media (max-width: $mobile) {
            background-color: var(--color-200);
          }
        }
      }

      &:nth-of-type(even) {
        .tariffs-table__title,
        .tariffs-table__row div {
          background-color: var(--color-200);

          @media (max-width: $mobile) {
            background-color: var(--color-100);
          }
        }
      }

      &:first-of-type {
        .tariffs-table__title {
          @media (min-width: $mobile) {
            border-radius: 4px 4px 0 0;
          }
        }
      }

      &:last-of-type {
        .tariffs-table__title {
          @media (min-width: $tabletSmall) {
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }

    .tariffs-table__row {
      @media (max-width: $mobile) {
        grid-template-columns: auto;
        width: 100%;
      }

      & div {
        @media (max-width: $mobile) {
          display: none !important;
          min-height: 64px;
        }
      }
    }
  }

  &__title,
  &__row div {
    padding: 8px 16px;
    font-size: 15px;
    line-height: 24px;
    min-height: 40px;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in 0s;

    &._gray {
      color: var(--color-500);
    }

    @media (max-width: $tabletSmall) {
      word-break: break-word;
    }
    @media (max-width: $mobile) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 12px;

    .icon--check {
      font-size: 22px;
    }

    @media (max-width: $pc) {
      grid-template-columns: 1fr 1fr 266px;
    }

    @media (max-width: $tabletSmall) {
      grid-template-columns: 1fr 176px 220px;
    }

    @media (max-width: $mobile) {
      grid-template-columns: repeat(3, 128px);
      grid-column-gap: 0;
    }

    div {
      justify-content: center;
      @media (min-width: $mobile) {
        transition: all 0.15s ease-in 0s;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }

      &:after {
        width: 24px;
        height: 24px;
        background-color: var(--color-main);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:nth-of-type(1) {
        [data-active-tariff="1"] & {
          @media (min-width: $mobile) {
            border-left: 1px solid var(--green-main);
            border-right: 1px solid var(--green-main);
          }
        }
      }

      &:nth-of-type(2) {
        [data-active-tariff="2"] & {
          @media (min-width: $mobile) {
            border-left: 1px solid var(--green-main);
            border-right: 1px solid var(--green-main);
          }
        }
      }

      &:nth-of-type(3) {
        [data-active-tariff="3"] & {
          @media (min-width: $mobile) {
            border-left: 1px solid var(--green-main);
            border-right: 1px solid var(--green-main);
          }
        }
      }
    }
  }

  &__silver {
    position: relative;
    background-color: var(--color-400);

    @media (min-width: $mobile) {
      border: 1px solid transparent;
      [data-active-tariff="1"] & {
        border-color: var(--green-main);
      }
    }
  }

  &__gold {
    color: var(--black-main);
    background: rgb(168, 127, 53);
    background: linear-gradient(
      150deg,
      rgb(168, 127, 53) 0%,
      rgb(168, 127, 53) 20%,
      rgb(255, 220, 98) 40%,
      rgb(255, 220, 98) 59%,
      rgb(168, 127, 53) 80%,
      rgb(168, 127, 53) 100%
    );

    @media (min-width: $mobile) {
      border: 1px solid transparent;
      [data-active-tariff="2"] & {
        border-color: var(--green-main);
      }
    }
  }

  &__platinum {
    color: var(--black-main);
    background: rgb(171, 171, 171);
    background: linear-gradient(
      140deg,
      #ffffff -19.7%,
      #ababab 5.25%,
      #ffffff 26%,
      #a8a8a8 62.22%,
      #ffffff 93.7%,
      #838383 125.45%
    );

    @media (min-width: $mobile) {
      border: 1px solid transparent;
      [data-active-tariff="3"] & {
        border-color: var(--green-main);
      }
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 616px repeat(3, 1fr);
    grid-column-gap: 12px;
    @media (max-width: $pc) {
      grid-template-columns: 1fr 200px 200px 266px;
    }
    @media (max-width: $tabletSmall) {
      grid-template-columns: 1fr 1fr 176px 220px;
    }
    @media (max-width: $mobile) {
      grid-template-columns: unset;
      grid-column-gap: 0;
      position: sticky;
      bottom: 76px;
      box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.7215686275);
    }

    .tariffs-table__row {
      grid-column: 2/3;
    }
  }

  &__box {
    padding: 16px 20px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    background-color: var(--color-100);
    height: max-content;
    @media (min-width: $mobile) {
      border-radius: 0 0 4px 4px;
      transition: 0.15s ease-in 0s;
      border: 1px solid transparent;
      border-top: 0;
    }

    @media (max-width: $mobile) {
      padding: 12px;
      display: none;
      flex-direction: row;
      gap: 30px;
      justify-content: center;
      border-radius: 0 0 4px 4px;
      background-color: #141414;
      .light & {
        background-color: #ebebeb;
      }
    }

    &:nth-child(1) {
      grid-column: 2/3;

      [data-active-tariff="1"] & {
        display: flex;
        @media (min-width: $mobile) {
          border-color: var(--green-main);
        }
      }
    }

    &:nth-child(2) {
      [data-active-tariff="2"] & {
        display: flex;
        @media (min-width: $mobile) {
          border-color: var(--green-main);
        }
      }
    }

    &:nth-child(3) {
      @media (min-width: $mobile) {
        background-color: transparent;
      }
      [data-active-tariff="3"] & {
        display: flex;
        @media (min-width: $mobile) {
          border-color: var(--green-main);
        }
      }
    }

    p {
      &:first-of-type {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 120%;
        @media (max-width: $mobile) {
          font-size: 18px;
          margin-bottom: 0;
        }

        span:nth-of-type(2) {
          display: inline-block;
          margin-left: 12px;
          color: var(--color-500);
          text-decoration: line-through;
          @media (max-width: $mobile) {
            margin-left: 10px;
            font-size: 18px;
          }
        }
      }
    }

    .p2 {
      margin-top: 20px;
      width: 188px;
      @media (max-width: $mobile) {
        font-size: 13px;
        margin: 0;
        max-width: 500px;
        width: 100%;
      }

      span {
        font-family: "Libertinus";
        font-weight: 400;
        font-size: 24px;
        display: block;
        line-height: 130%;
        margin-bottom: 8px;
        @media (max-width: $mobile) {
          font-size: 18px;
        }
      }
    }

    .button {
      @media (max-width: $mobile) {
        max-width: 260px;
      }
    }

    &:nth-child(1) {
      grid-column: 2/3;
      @media (max-width: $tabletSmall) {
        grid-column: 1/3;
      }
    }

    &:nth-child(2) {
      @media (min-width: $mobile) {
        grid-column: 3/4;
      }
    }

    &:nth-child(3) {
      gap: 10px 24px;
      @media (max-width: $mobile) {
        flex-wrap: wrap;
      }
      @media (min-width: $mobile) {
        grid-column: 4/5;
        gap: 0;
      }
    }
  }

  &[data-active-tariff="1"] {
    .tariffs-table__body {
      .tariffs-table__row div:nth-of-type(1) {
        @media (max-width: $mobile) {
          display: flex !important;
        }
      }
    }
  }

  &[data-active-tariff="2"] {
    .tariffs-table__body {
      .tariffs-table__row div:nth-of-type(2) {
        @media (max-width: $mobile) {
          display: flex !important;
        }
      }
    }
  }

  &[data-active-tariff="3"] {
    .tariffs-table__body {
      .tariffs-table__row div:nth-of-type(3) {
        @media (max-width: $mobile) {
          display: flex !important;
        }
      }
    }
  }
}
