@import "./src/styles/variables.scss";

.filter {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-top: 20px;
  }

  &__search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  &-countries {
    &__body {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(4, auto);
      align-items: center;
      width: 100%;
      margin: 12px 0;

      .checkbox label{
        gap: 4px;
      }

      @media (max-width: $tabletSmall) {
        grid-template-columns: repeat(3, auto);
      }

      @media (max-width: $mobileExtraSmall) {
        grid-template-columns: repeat(2, auto);
      }
    }
  }

  &-button {
    &__container {
      display: flex;
      justify-content: center;
    }
  }
}
