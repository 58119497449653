@import "./src/styles/variables.scss";

.res {
  &__grid{
    margin: 0;
    padding: 0;
    @media (max-width: $tabletSmall) and (min-width: $mobile){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px 8px;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: minmax(312px, auto) 1fr minmax(255px, auto);
    gap: 0 64px;
    align-items: center;
    background-color: var(--color-200);
    border-radius: 24px;
    padding: 24px;

    @media (max-width: $pc) {
      gap: 0 32px;
    }

    @media (max-width: $tablet) {
      grid-template-columns: minmax(312px, auto) 1fr minmax(140px, auto);
      gap: 0 24px;
    }

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
      padding: 16px;
      align-items: unset;
      gap: 16px;
    }
  }

  &__user__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.decline {
      opacity: 40%;
    }

    @media (max-width: $mobile) {
      gap: 12px;
    }
  }

  &__user__block {
    display: flex;
    gap: 10px;
    align-items: center;
    //justify-content: space-between;
  }

  &__info__block {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $tablet) {
      gap: 16px;
    }

    &.decline {
      opacity: 40%;
    }
  }

  &__info__block__money {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    gap: 16px;
    width: 100%;
    @media(max-width: $tabletSmall){
      gap: 12px;
      align-items: center;
      align-content: center;
    }
  }

  &__user-contants{
    display: flex;
    align-items: center;
    gap: 8px;
    a{
      white-space: nowrap;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    span{
      margin: 0;
    }
  }

  &__var {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media(max-width: $tabletSmall) {
      margin-top: auto;
    }
    .rating-stars{
      height: auto;
      max-width: 100px;
    }

    &__actions {
      display: flex;
      gap: 8px;

      @media (max-width: $tablet) and (min-width: $tabletSmall){
        flex-direction: column;
      }
    }

    &__text {
      @media (max-width: $tablet) and (min-width: $tabletSmall) {
        text-align: center;
      }
    }

    &.done {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }

    &.approve {
      @media(max-width: $tabletSmall){
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
      }
      .res__status{
        flex-direction: column;
      }
    }

    &.decline{
      align-items: flex-end;
    }

    &.work {
      gap: 12px;
      align-items: normal;

      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }

    &.done {
      flex-direction: column;
      @media (max-width: $tabletSmall) {
        flex-direction: row-reverse;
        align-items: flex-start;
      }
      .color-green{
        @media (max-width: $tabletSmall) {
          margin-top: auto;
        }
      }
      .res__status{
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 280px;
        @media (max-width: $tabletSmall) {
          justify-content: flex-start;
        }
      }
      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }
  }

  &__ad {
    display: flex;
    align-items: center;
    &__photo {
      width: 86px;
      object-position: center;
      object-fit: cover;
      height: 104px;
      border-radius: 4px;
    }

    span {
      width: 86px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
.res__root.balance {
  height: unset;
  @media (max-width: $mobile) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .res__ad {
      order: 2;
      width: 100%;
    }
  }
}
.balance-page {
  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
    max-width: 1032px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
  }

  &__tabs {
    margin-left: 0;

    @media (max-width: $mobile) {
      width: 100%;

      .header__tab {
        width: 100%;
        text-align: center;
      }
    }
  }

  // &__tab {
  //   padding: 7px 16px 12px;
  //   color: var(--color-700);
  //   position: relative;
  //   transition: all 0.15s ease;
  //   width: max-content;

  //   &:before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     height: 2px;
  //     background-color: var(--color-main);
  //     transition: inherit;
  //     transform: scale(0);
  //     .header--type-one & {
  //       content: unset;
  //     }
  //   }

  //   &:hover {
  //     color: var(--color-main);
  //     cursor: pointer;
  //     .header--type-one & {
  //       text-decoration: underline;
  //     }

  //     &:before {
  //       transform: scale(1);
  //     }
  //   }

  //   &:after {
  //     top: 2px;
  //     right: 4px;
  //   }

  //   &._active {
  //     color: var(--color-main);
  //     &:before {
  //       transform: scale(1);
  //     }
  //   }
  // }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: space-between;

    form {
      width: 250px;
    }

    .agency-block__content {
      padding-right: 6px;
    }

    .input-label  {
      background: var(--bg-main);
    }

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    @media (max-width: $mobile) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &.your__balance {
    display: flex;
    flex-direction: column;
  }

  // &.day__balance {
    // display: grid;
    // grid-template-columns: 1fr max-content;
    // padding: 8px 16px;
    // gap: 12px;
    // border-radius: 12px;
    // border: 1px solid var(--color-400);
    // width: 200px;
    // align-items: center;
  // }

  &__select {
    // display: grid;
    display: flex;
    // grid-template-columns: auto 1fr auto;
    gap: 8px;

    .dropdown__body {
      min-width: 100%;

      .search__label{
        button{
          width: 40px;
          max-width: 20px;
          height: 40px;
          padding: 6px 20px;
          right: 0px;
          &:hover{
            outline: 1px solid var(--color-400);
            color: var(--red-main);
          }
        }
      }
    }

    @media (max-width: $mobile) {
      // grid-template-columns: auto 1fr;
      flex-wrap: wrap;
      .dropdown {
        width: 100%;

        .button {
          width: 100%;
        }
        .calendar {
          justify-content: space-between;
        }
        
      }
    }

    @media (max-width: $mobileSmall) {
      display: flex;
      flex-direction: column;
    }
  }

  &__updown {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 12px;
    align-items: center;
    // gap: 8px;
    border-radius: 4px;

    &.up {
      background-color: var(--green-300);
      @media (max-width: $mobile) {
        width: 100%;
        justify-content: center;
      }
    }

    &.down {
      background-color: var(--red-300);
      @media (max-width: $mobile) {
        width: 100%;
        justify-content: center;
      }
    }

    @media (max-width: $mobileSmall) {
      padding: 12px 4px;
    }
  }
}

.date-dropdown {
  @media (max-width: $mobileExtraSmall) {
    .dropdown {
      width: fit-content;
      flex-grow: 1;
      .calendar {
        padding: 8px;
        font-size: 12px;
      }
    }
  }
}

