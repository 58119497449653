@import "./src/styles/variables";

.info-form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (min-width: $mobileExtraSmall) {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $tabletSmall) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--four {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}



.info-address, .info-address-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &__row {
    display: flex;
    gap: 12px;
    align-items: center;
    @media (max-width: $mobileSmall) {
      flex-wrap: wrap;
      justify-content: space-between;
      .search {
        min-width: 60%;
        max-width: 80%;
      }
    }
  }

  .info-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
    min-width: 66%;

    &__top{
      display: flex;
      flex-wrap: nowrap;
      min-width: 30%;
      max-width: 100%;
      gap: 12px;
      justify-content: flex-start;
      flex-direction: row;
      
      @media (min-width: $mobile) {
        flex-direction: row-reverse;
        max-width: 50%;
        min-width: max-content;
        justify-content: start;
      }
      .select-countries {
        min-width: 150px;
        max-width: 70%;
      }
    }
    
    .select-countries {
      min-width: 150px;
      max-width: 70%;
      
    }

   
    
  }

  .create-error {
    color: var(--red-800);
  }

  .geo-error {
    .header__search {
      pointer-events: none;
    }
  }
}

.info-address-container{
  .info-container__top{
    border: 1px solid var(--color-400);
    border-radius: 4px;
    max-height: 40px;

    .select-countries{
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  @media screen and (min-width: $mobile) {
   flex-direction: row;
  }
}