@import "src/styles/variables";

.lady-end-call-bar {
  border: 1px solid var(--color-300);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  padding: 1rem;
  border-radius: 16px;
  color: var(--color-main);
  transition: color var(--switch-theme-time);
  font-weight: 400;
  font-family: "Libertinus";
  font-size: 1rem;
}

.videoDisabled {
  border: none;
  text-align: center;
  svg {
    width: 6rem !important;
    height: 6rem !important;
    color: red !important;
  }
  p {
    color: var(--color-main);
    transition: color var(--switch-theme-time);
    font-weight: 400;
    font-family: "Libertinus";
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}

.videochat {
  padding: 32px;
  background-color: var(--bg-main);

  .light & {
    background-color: var(--bg-main);
  }

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .button__close {
    position: fixed;
    z-index: 6;
    background-color: transparent;
  }

  .popup-form__inner {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: max-content;
      gap: 16px;
      @media (max-width: $tabletSmall) {
        width: 100%;
      }
    }
  }

  &__preview {
    width: 21rem;
    border: 0;
    border-radius: 0;
    padding: 24px 0px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-self: center;
    align-items: center;
    border-radius: 4px;

    .stars-box {
      span {
        gap: 16px;
      }
      .videochat__timer {
        width: 100%;
        flex-direction: row;
        justify-content: center;
      }
    }

    .videochat__timer {
      width: max-content;
      border-color: var(--color-300);
    }

    ~ span {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__image {
    width: 86px;
    height: 104px;
    border-radius: 12px;
    margin: 0 auto;
    object-fit: cover;
  }

  &__timer {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    width: 224px;
    color: var(--color-600);

    border: 1px solid;
  }

  &__dotted {
    display: flex;
    justify-content: center;
    width: 200px;
    background: linear-gradient(
      to right,
      var(--color-main) 20%,
      var(--green-main) 30%,
      var(--green-main) 70%,
      var(--color-700) 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;

    @keyframes textShine {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }

    &:after {
      display: inline-block;
      animation: dotty steps(1, end) 2s infinite;
      content: "";
    }

    @keyframes dotty {
      0% {
        content: "";
      }
      25% {
        content: ".";
      }
      50% {
        content: "..";
      }
      75% {
        content: "...";
      }
      100% {
        content: "";
      }
    }
  }

  &__close,
  &__bar {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__bar {
    width: auto;
    display: flex;
    gap: 24px;
    border-radius: 16px;
    padding: 12px;
    max-width: 876px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    background-color: transparent;
    overflow: hidden;
    background: var(--black-400);
    backdrop-filter: blur(32px);
    -webkit-backdrop-filter: blur(32px);

    p:not(.color-green) {
      color: var(--white-600);
    }

    @media (max-width: $tabletSmall) {
      flex-direction: column;
      gap: 8px;
      width: max-content;
    }
    @media (max-width: $mobileExtraSmall) {
      width: 336px;
    }

    .videochat__timer {
      // flex-direction: row;
      // padding: 10px;
      border-color: var(--white-300);
      justify-content: space-between;
      width: 144px;
      flex-direction: column;
      gap: 0;
      padding: 8px 16px;
      @media (max-width: $tablet) {
        width: 210px;
      }
      @media (max-width: $tabletSmall) {
        // width: 144px;
        // flex-direction: column;
        // gap: 0;
        // padding: 8px 16px;
      }
      @media (max-width: $mobileSmall) {
        width: 116px;
        p {
          font-size: 12px;
        }
      }
    }

    &:not(.checkprofile__bar) {
      .button,
      .button-square {
        color: var(--white-main);
        background-color: var(--white-400);
        @media (max-width: $mobile) {
          height: 60px;
        }
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;
    gap: 12px;
    @media (max-width: $tabletSmall) {
      gap: 8px;
      .button:last-of-type {
        margin-left: 12px;
        @media (max-width: $mobile) {
          margin: 0;
        }
      }
    }
  }

  &__video-button {
    @media (max-width: $mobile) {
      min-width: 60px;
      width: 60px;
      min-height: 60px;
    }

    &._active {
      color: var(--red-main);
      background-color: var(--red-200);
    }
  }
}
