@import 'src/styles/variables';

.agency {
    & > section:not(.breadcrumbs, .agency__banner, .swiper),
    & > div:not(.breadcrumbs, .agency__banner, .swiper) {
        padding-top: 24px;
        padding-bottom: 24px;
        @media(max-width: $mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &.ssr{
       @media (max-width: $mobile) {
        margin-top: 200px;
       }
    }

    &__item {
        position: absolute;
        bottom: 0;
        left: 16px;
        border-radius: 24px;
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-template-rows: max-content;
        align-items: center;
        column-gap: 16px;
        padding: 20px 24px;
        background-color: var(--color-700-reverse);
        --webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);

        @media(max-width: $mobileSmall) {
            grid-template-columns: 112px 1fr;
            gap: 12px;
            padding: 16px;
            left: 6px;
            right: 6px;
            background-color: var(--color-500-reverse);
        }

        &-desc {
            grid-row: 1/3;
            grid-column: 2/3;
            @media(min-width: $mobileSmall) {
                margin-bottom: 12px;
            }
        }

        picture {
            grid-row: 1/4;
            grid-column: 1/2;
            @media(max-width: $mobileSmall) {
                grid-row: 1 / 3;
            }

            img {
                border-radius: 12px;
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        .model__review {
            &, > span {
                display: inline-block;
            }
        }

        .button {
            grid-row: 3/4;
            grid-column: 2/3;
            
            @media(max-width: $mobileSmall) {
                grid-column: 1 / 3;
            }
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        @media(max-width: $tablet) {
            flex-direction: column;
        }
    }

    &__info {
        flex: 0 0 45%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        min-width: 45%;
        width: 100%;
    }

    &__media__interior{
        flex: 1 0 50%;
    }

    &__buttons {
        display: flex;
        gap: 4px;
        .button.button-icon{
            padding: 6px;
            height: 50px;
            width: 50px;
            i{
                font-size: 24px;
            }

            @media (max-width: $mobileSmall) {
                padding: 6px;
                height: 40px;
                width: 40px;
                i{
                    font-size: 18px;
                }
            }
            
        }
        
    }

    &__links {
       display: flex;
       flex-wrap: wrap;
       gap: 16px;
       a{
        display: flex;
        gap: 8px;
        align-items: center;
       }
       a:hover{
        i{
            text-decoration: none;
            text-underline-offset: 4px;
        }
        i::before{
            text-decoration: none;

        }
       }
    }

    &__address {
        padding-right: 28px;

        &:after {
            mask-size: 20px;
            right: 0;
        }
    }

    .schedule-table {
        @media(min-width: $tablet) {
            min-width: 702px;
            min-height: 350px;
        }
        @media(max-width: $mobile) {
            flex-direction: column;
        }

        &__wrapper {
            @media(max-width: $mobile) {
                max-width: 100%;
            }
        }

        &__work-time {
            @media(max-width: $mobile) {
                width: 100%;
            }
        }
    }

    &__media {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__video{
            flex: 0 0 50%;
        }
        @media(max-width: $pc){
            gap: 26px;
        }
        @media(max-width: $tabletSmall){
            flex-wrap: wrap;
        }
    }

    &-tabs{
        .tabs__content{
            width: 100%;
        }
        &__slide {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            @media(max-width: $mobileSmall){
                grid-template-columns: repeat(2, 1fr);
            }
            img {
                border-radius: 4px;
                aspect-ratio: 112 / 135;
                object-position: center;
                object-fit: cover;
                height: 100%;
                width: 100%;
                cursor: zoom-in;
            }

            &.interior-mobile{
                display: flex;
            } 
        }
        
    }

    
    .tabs{
        margin-bottom: 0;
        max-width: 710px;
        width: 100%;
        @media(max-width: $pc){
            max-width: 550px;
        }
        @media(max-width: $tablet){
            max-width: 480px;
        }
        @media(max-width: $tabletSmall){
            max-width: 100%;
        }
        .button{
            max-width: max-content;
            margin: 0 auto;
            margin-top: 24px;
        }
    }
    &__block{
        width: 100%;
    }
}



.agency-filter{
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            @media(min-width: $mobile){
                margin-bottom: 0;
            }
        }
    }
    &__nav{
        display: flex;
        gap: 4px;
        .dropdown{
            color: var(--color-main);
            @media (min-width: $mobileSmall) {
                margin-left: auto;
            }
            @media(max-width: $mobileSmall){
                &,  .button, .dropdown__body{
                    width: 100%;
                }
                .chevrone{
                    margin-left: auto;
                }
            }
            .dropdown__body{
                left: auto;
                right: 0;
            }
        }
        @media(max-width: $pc){
            flex-wrap: wrap;
        }
    }
    &__box{
        padding: 12px 24px 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-200);
        border-radius: 4px;
        margin-top: 32px;
        gap: 8px;
        

        @media(max-width: $tabletSmall){
            flex-wrap: wrap;
            margin: 32px auto 0;
            gap: 24px;
        }

        .button{
            max-height: 60px;
            width: 316px;
            justify-content: center;
            margin: 0 auto;
            @media(max-width: $mobileExtraSmall){
                margin: 0;
                width: 100%;
            }
        }

        @media(max-width: $mobile){
            padding: 16px;
        }

    }
    &__inner{
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
    }
    &__counter:first-child{
        width: 30%;
        gap: 8px;
        display: flex;
        flex-direction: row;
        label{
            max-width: 50%;
        }
        div:first-child{
            width: fit-content;
        }
        .search{
            min-width: 50%;
        }
    }
    &__counter:last-child{
        width: 50%;
        gap: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }
    &__count{
        padding: 12px;
        text-align: center;
        background-color: var(--color-200);
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 20px;
        justify-content: center;
        @media(max-width: $mobileExtraSmall){
            width: 158px;
            padding: 12px 24px;
        }
    }
    &__1group{
        max-width: 20%;
        min-width: 20%;
        @media (max-width: $tabletSmall) {
            min-width: 100%;
            justify-content: space-between;
            label{
                width: 100%;
            }
        }
    }
    &__2group{
        max-width: 100%;
        min-width: 35%;
        div{
            label{
                background-color: var(--color-200);
            }
        }
        @media (max-width: $tabletSmall) {
            min-width: 100%;
            .button-square-l{
                min-width: 60px;
                width: 60px;
                height: 60px;
            }
        }
    }
    &__3group{
        max-width: 27%;
        min-width: 27%;
        @media (max-width: $tabletSmall) {
            min-width: 100%;
            justify-content: space-between;
            label{
                width: 50%;
            }
        }
        label{
            min-width: 49%;
        }
    }
}

.previews-counts-container{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
    @media(max-width: $mobileExtraSmall){
        flex-wrap: wrap;
        gap: 16px;
    }
    div{
        gap: 16px;
        justify-content: space-between;
        span{
            line-height: 20px;
        }
    }
}

