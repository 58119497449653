@import "./src/styles/variables";

.select {
  border-radius: 4px;

  &,
  &-countries {
    position: relative;
    height: max-content;
  }

  &-countries {
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 15px;
    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }

  &__button {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    align-items: center;
    gap: 6px;
    transition: background-color 0.2s ease 0s;
    height: 40px;
    cursor: pointer;
    color: var(--color-main);
    font-size: 15px;
    width: 100%;
    border-radius: 4px;
    padding: 8px 11px; 
    &:not(._no-arrow) {
      padding-right: 28px;
    }

    &.no-outline{
      outline: none;
    }

    @media (max-width: $mobile) {
      font-size: 14px;
      height: 32px;
    }

    @media (max-width: $mobileSmall) {
      padding: 6px;
    }

    @media (max-width: $tablet) {
      border: 1px solid var(--color-400);
    }

    .select-countries & {
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  
    &:hover,
    &:focus-within {
      background-color: var(--color-200);
    }

    .light & {
      &:hover,
      &:focus-within {
        background-color: var(--color-300);
      }
    }
  }

  &__arrow {
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    pointer-events: none;
    &:before {
      transition: all 0.2s;
      transform: rotate(0);

      .select_active & {
        transform: rotate(180deg);
      }
    }
  }

  &__subtitle {
    display: block;
    color: var(--color-600);
    width: 100%;
    line-height: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    font-size: 13px;
    margin-bottom: auto;
    text-align: left;
  }

  &__placeholder {
    font-size: 13px;
    color: var(--color-600);
    pointer-events: none;
    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }

  &__search {
    position: relative;
    background: var(--bg-main);
    transition: background-color var(--switch-theme-time);
    border-radius: 4px;
    cursor: text;
    width: 100%;

    .search__input {
      pointer-events: none;
      padding: 12px 46px 12px 12px;

      @media (max-width: $mobile) {
        padding-right: 12px;
      }
    }

    .icon--close {
      cursor: pointer;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .text-dots {
    pointer-events: none;
  }

  .dropdown__search-select {
    transition: background-color var(--switch-theme-time);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 196px;
    width: fit-content;
    overflow-y: auto;

    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
    }

    .checkbox {
      padding: 10px 8px;

      input {
        left: 6px;
      }
    }

    .checkbox.checkbox_checked,
    .checkbox:hover {
      background-color: var(--color-200);
    }
  }

  &-city {
    padding: 9px 8px 9px 12px;
    padding-right: 28px !important;
    background-color: transparent;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;

    .selec__title{
      gap: 4px;
    }
    .select__flag {
      width: 28px;
      height: 20px;
      min-height: 12px;
      @media (max-width: $tablet) {
        height: 18px;
      }
    }

    .select__arrow {
      @media (max-width: $tablet) {
        width: 16px;
        height: 16px;
      }
    }

    &--word {
      position: unset;
      display: inline-block;
      font-weight: 400;
      font-family: "Libertinus";
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      border-bottom: 1px dashed var(--color-main);

      &:hover,
      &:focus-within {
        background-color: transparent;
      }

      .select__body {
        width: max-content;
        font-size: 15px;

        
      }
    }
  }

  &__body {
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--black-light);
    border-radius: 4px;
    left: 0;
    padding: 6px;
    min-width: 100%;
    max-width: 360px;
    top: calc(100% + 4px);
    opacity: 0;
    z-index: 3;
    transition: all 0.3s;
    box-shadow: var(--box-shadow-main);
    cursor: default;

    .select_active & {
      visibility: visible;
      opacity: 1;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: max-content;
      max-height: 260px;
      overflow-y: auto;
      flex-shrink: 0;

      .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        padding: 10px 14px;
        background: var(--color-300);
        transition: background-color var(--switch-theme-time);

        &:hover {
          background: var(--color-300);
        }
      }

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &-right {
      width: 100%;
      max-height: 260px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 200px;
      .dropdown__search-select {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }

      .checkbox {
        input {
          pointer-events: none;
        }

        label {
          width: 100%;
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }

      @media (max-width: $mobile) {
        width: 100%;
      }

      .select__search {
        margin-top: 0;
      }

      div{
        button{
          justify-content: start;
        }
      }
    }

    .select-countries & {
      box-shadow: var(--box-shadow-big);
      gap: 12px;
      min-width: 200px;
      @media (min-width: $mobile) {
        flex-direction: row;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: left;
    flex-direction: row-reverse;
    padding: 8px 4px;
    gap: 4px;
    width: 100%;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease 0s;
    cursor: pointer;
    font-size: 15px;
    @media (max-width: $mobile) {
      font-size: 14px;
    }
    &:hover {
      background-color: var(--color-200);
    }

    & svg {
      width: 22px;
      height: 16px;
      margin-right: 6px;
    }
  }

  &._disabled {
    .select__subtitle {
      color: var(--color-400);
      font-size: 13px;
      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }

  &.select-field:not(.select__button){
    
    .select__button{
      margin-top: 6px;
      padding: 0px 4px;
    }

    &:hover, :focus{
      background-color: transparent;
    }
    
    span.select__subtitle{
      position: absolute;
      left: 12px;
      width: fit-content;
      padding: 0 4px;
      transform: translateY(-22px);
      color: var(--green-secondary);
      background-color: var(--color-main-reverse);
    }
  }

  &.select-countries.select-field{
    span.select__subtitle{
      transform: translateY(-10px);
    }
    span.select__title{
      transform: translate(0px , 10px);
    }
  }
}

h1 ~ .select-city {
  padding: 0;
  border: none;
  position: unset;
}
