@import "./src/styles/variables";

.navigation {
  &__container {
    transition: color var(--switch-theme-time);
    color: var(--color-main);
    padding-bottom: 31px;

    &.ssr{
      @media (max-width: $mobile) {
        margin-top: 150px;
      }

      .pagination{
        margin-top: 12px;
      }

      .navigation__nav{
        flex-wrap: wrap;
      }
    }

    .dropdown {
      > button {
        //background-color: var(--color-400);
        

        &:hover,
        &:focus-within {
          background-color: var(--color-200);
        }
      }
    }

    .grid-cards {
      .navigation__empty {
        display: block;
        max-width: 100%;
      }
    }
  }

  &__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 72px;
    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      h1{
        text-align: center;
      }
    }

    &-wrapper {
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 8px;
      @media (min-width: $tablet) {
        .notifications__main-button {
          display: none;
        }
      }
      @media (max-width: $mobile) {
        width: 100%;
      }

      > a {
        text-decoration: none;
        font-family: "Inter";
        padding: 9px 12px;
        @media (max-width: $tabletSmall) {
          display: none;
        }
      }
    }

    h1 {
      margin: 0;
      @media (max-width: $tabletSmall) and (min-width: $mobile) {
        max-width: 624px;
      }
    }
    .select{
      &-countries{
        width: 100%;
      }
      &__button{
        display: none;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 40px;
    @media (max-width: $mobileSmall) {
      margin-bottom: 36px;
      justify-content: space-between;
    }

    .on-map{
      margin-left: auto;
      color: var(--color-main);
      border-color: transparent;
      background-color: var(--green-400);
      &:hover{
        background-color: var(--green-500);
        border-color: var(--green-accent);
      }
    }
    

    .dropdown {
      .button {
        @media (max-width: $mobileExtraSmall) {
          gap: 6px;
        }
      }

      .chevrone {
        width: 16px;
        height: 16px;
      }
    }

    

    .select {

      .select__body {
        left: auto;
        right: 0;
      }
    }
  }

  &__mobile-filter{
    max-height: 32px;
    padding: 6px 12px;
    font-weight: 400;
    align-items: center;
    line-height: 20px;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 6px;
    // margin-bottom: 16px;
    .checkbox-link{
      display: none;
    }

    > .checkbox-button,  .checkbox{
      display: none;

      @media (min-width: $mobileSmall) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: flex;
        }
      }
      
      @media (min-width: $tabletSmall) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          display: flex;
        }
      }
      @media (min-width: $tablet) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          display: flex;
        }
      }

      @media (min-width: $pc) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          display: flex;
        }
      }
      

      @media (min-width: 1475px) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9) {
          display: flex;
        }
      }
    }

    a {
      text-decoration: none;
      display: flex;
    }

    &.ssr{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__more {
    display: none;
    @media (min-width: $mobile) {
      display: flex;
    }

    .checkbox-link{
      display: flex;
      &:hover{
        background-color: var(--green-800);
      }
    }

    .button{
      border-radius: 4px;
    }

    .checkbox, .checkbox-button {
      border-radius: 4px;
      background-color: transparent;

      &:hover{
        background-color: var(--green-500);
      }
      ~.checkbox-link{
        background-color: var(--green-500);
        &:hover{
          background-color: var(--green-800);
        }
      }

      @media (min-width: $mobileSmall) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: none;
        }
      }
      
      @media (min-width: $tabletSmall) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          display: none;
        }
      }

      @media (min-width: $tablet) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          display: none;
        }
      }

      @media (min-width: $pc) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          display: none;
        }
      }
      

      @media (min-width: 1475px) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9) {
          display: none;
        }
      }
    }
  }

  &__show-more {
    width: 300px;
    margin: 32px auto 0;
    display: flex;
    @media (max-width: $mobileExtraSmall) {
      width: 100%;
    }
  }
}
