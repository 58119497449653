@import "src/styles/variables";

.schedule-table {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  

  h3 {
    margin-bottom: 0;
  }

  table{
    overflow-x: auto;

    thead{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    tbody{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }

    th{
      font-weight: 400;
    }
  }

  &__prices {
    text-align: center;
    width: 100%;
    color: var(--color-main);
    display: flex;
    flex-direction: column;
    height: max-content;
    row-gap: 2px;
  }

  &__row {
    display: flex;
    gap: 2px;
    height: 100%;
    * {
      padding: 0 9px;
      min-height: 56px;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
      overflow: hidden;
      width: 100%;
      min-width: 85px;
      font-size: 15px;
      @media (max-width: $mobile) {
        font-size: 14px;
      }

      &:first-of-type {
        justify-content: flex-start;
        min-width: 75px;
        max-width: 90px;
        &:not(.schedule-table__time) {
          color: var(--color-600);
        }
      }

      &:last-of-type{
        word-break: break-word;
      }

      &:not(.schedule-table__title, &:first-of-type) {
        font-size: 18px;
        @media (max-width: $mobile) {
          font-size: 15px;
        }
  
      }
    }

    &:nth-child(odd) * {
      &:not(.schedule-table__title) {
        background-color: var(--color-200);
      }

      &:first-of-type {
        background-color: var(--color-300);
      }
    }

    &:nth-child(even) * {
      background-color: var(--color-100);
      &:first-of-type {
        background-color: var(--color-200);
      }
    }
  }

  &__title {
    width: 100%;
    background-color: var(--green-400);
  }

  &__work-time {
    //min-width: 144px;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-300);
    border-radius: 4px;
    overflow: hidden;
    color: var(--color-main);
    > * {
      border-bottom: 1px solid var(--color-300);
      height: 100%;
      display: flex;
      align-items: center;
      i:after {
        background-color: var(--green-main);
      }
      @media(max-width: $mobileSmall){
        justify-content: flex-start;
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 12px;
  }

  &__day {
    border-right: 1px solid var(--color-300);
    //width: 40px;
    width: 74px;
    min-width: 40px;
    height: 100%;
    min-height: 39px;
    font-size: 18px;
    color: var(--color-600);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__work-hours {
    margin: 0 auto;
    padding: 6px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
  }

  &__discounts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--color-main);
    font-size: 24px;
    text-align: center;
    width: 100%;
  }

  &__discount {
    padding: 12px;
    border-radius: 4px;
    background-color: var(--color-300);
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 102px;
  }

  @media (max-width: $mobileSmall) {
    max-width: calc(100vw - 16px);
    table{
      max-width: 100%;
    }
    thead, tbody{
      width: 100%;
    }
    th, td{
      overflow: visible;
    }
  }
}
