@import "./src/styles/variables.scss";

.verads {
  &.control {
    display: flex;
    border-radius: 4px;
    padding: 16px 16px 16px 16px;
    flex-direction: column;
    background-color: var(--color-100);
    width: 100%;
    height: 100%;

    &.fd-row {
      flex-direction: row;
      height: unset;
      padding: 24px;

      @media (max-width: $mobile) {
        padding: 12px;
      }
    }

    @media (max-width: $mobileSmall) {
      padding: 0px;
      padding-bottom: 12px;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    // color: var(--color-main);
    // font-size: 20px;
    // line-height: 23px;

    @media (max-width: $mobileSmall) {
      font-size: 18px;
      line-height: 22px;

      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__photo {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: stretch;

    @media (max-width: $mobileExtraSmall) {
      flex-direction: column;
    }
  }

  &__examplePhoto {
    display: flex
    ;
        flex-direction: column;
        justify-content: center;
        padding: 12px;
        background-color: var(--color-200);
        border-radius: 4px;
  }

  &__secure {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
  }

  &__ten {
    font-size: 24px;
    line-height: 130%;
    white-space: nowrap;
    display: block;
    color: var(--color-main-reverse);
    font-family: "Libertinus";
    font-weight: 400;
  }
}

.test {
  background-color: var(--color-200);
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: 1px dashed;
}

.speedup-moderation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  outline: 1px dashed var(--green-500);
  width: 100%;
  border-radius: 4px;
}

.speedup-moderation:hover{
  background: var(--green-200);
}
