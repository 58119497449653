@import "src/styles/variables";

.about-page{
    &__container{
        padding-bottom: 40px;
        width: 100%;

        .tabs__nav{
            max-width: 300px;

            @media (max-width: $mobile) {
                max-width: unset;
                width: 100%;
            }
        }

        .about__container,
        .benefits__container{
            padding: 40px 0;
        }

        .attention-box{
            p{
                display: flex;
                flex-direction: column;
                gap: 4px;
                font-size: 15px;
                @media(max-width: $tablet) {
                    font-size: 14px;
                }
            }
        }
    }
}