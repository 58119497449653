@import "./src/styles/variables.scss";

.create-page__form__services {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .create-page__input {
    &-container{
      width: 100%;
      padding: 12px;
      gap: 12px;
      h3{
        line-height: 40px;
        align-self: center;
        margin-bottom: 0;
      }

      .select {
        height: 60px;

        .select__arrow {
          transition: all 0.3s;
          right: 16px;
          top: 20px;
          position: absolute;
        }
      }
    }
    &-buttons{
      padding: 0;
    }
  }
}

.form-services {
  &__top {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__preference {
    display: grid;
    grid-template-columns: auto 332px;
    gap: 16px;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    textarea {
      min-height: 160px;
    }

    .loader-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    &--title {
      font-size: 24px;
      line-height: 26px;
      color: var(--color-main);
    }
    &--block {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      p:first-of-type {
        width: 50%;
        color: var(--color-main);
      }
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__section {
    width: 100%;

    @media (max-width: $mobile) {
      width: 100%;
    }

    &--title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 24px;

      color: var(--color-main);

      hr {
        height: 1px;
        width: 14px;
        background-color: var(--color-700);
      }

      .checkbox {
        label {
          color: var(--color-600);

          &:hover {
            color: var(--color-main);
          }
        }
      }
    }

    &--checkbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    &--extra {
      text-underline-offset: 4px;
      text-decoration-thickness: 1px;
      text-decoration: underline;
      cursor: pointer;
      display: none;

      @media (max-width: $tabletSmall) {
        display: block;
      }

      &:hover {
        text-decoration: none;
        color: var(--color-main);
      }
    }

    &--extrabox {
      display: none;

      @media (max-width: $tabletSmall) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
      }
    }

    &--comment {
      display: none;
      width: 100%;
      z-index: 2;
      margin-top: 12px;
      gap: 8px;
      &._open {
        display: flex;
      }

      fieldset:nth-of-type(1) {
        flex-grow: 1;
        flex-basis: 60%;
        background: transparent;

        &:hover,
        &:focus-within {
          background: transparent;
        }
      }
      fieldset:nth-of-type(2) {
        width: 120px;
        min-width: 100px;
        font-size: 13px;
        margin-left: auto;

        &:hover,
        &:focus-within {
          background: var(--color-400);
        }
      }
    }
    &--column {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &--item {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 4px;

      .services-checkbox {
        background-color: var(--color-800);

        label {
          color: var(--color-main-reverse) !important;
        }
      }
      hr {
        width: 15px;
        background-color: var(--color-600);
      }

      input {
        pointer-events: none;
      }
    }
  }
  &__top-item {
    display: grid;
    grid-template-columns: 7fr 1fr 1fr;
    gap: 16px;
    grid-column: span 2 / span 2;

    @media (max-width: $mobile) {
      display: flex;
      flex-wrap: wrap;  
      .select{
        margin-left: auto;
        min-width: 75px;
      }
    }

    & > fieldset{
      align-self: flex-end;
      margin-bottom: 12px;
    }

    & > span {
       align-self: flex-end; 
       margin-bottom: 12px;
      color: var(--color-main);
      border: none;
      background-color: var(--color-200);
    }

    .create-page__input-container{
      padding-left: 0px;
    }
  }
}

.form-services__section--item:hover .form-services__section--extra {
  display: block;
}

.form-services__section--item:hover .form-services__section--extrabox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
