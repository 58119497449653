@import 'src/styles/variables';

.registration {
    &__container {
        padding-top: 24px;
        padding-bottom: 202px;
        @media(max-width: $mobile){
            padding-top: 10px;
            padding-bottom: 40px;
        }

        .fake-checkbox{
            transform: translateX(-10px);
            gap: 12px;
            font-size: 18px;

            line-height: 29px;
            font-family: 'Libertinus';
            @media(max-width: $mobile){
                transform: translateX(-6px);
            }
            @media(max-width: $mobileSmall){
                align-items: flex-start;
            }

            i{
                color: var(--white-main);
            }
        }

        h2  {
            margin-bottom: 24px;
            color: var(--color-700);
            @media(max-width: $mobile) {
                margin-bottom: 20px;
            }
        }

    }

    &__account {
        display: flex;
        gap: 16px;
        @media(max-width: $tablet){
            flex-direction: column;
        }
        @media(max-width: $mobile){
            gap: 20px;
        }
    }

    &__radio-group {
        max-width: 512px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.topup {
            min-width: 400px;
            max-width: 400px;
        }
        @media(max-width: $pc){
            max-width: 442px;
        }
        @media(max-width: $tablet){
            max-width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            .registration__radio{
                flex-grow: 1;
                flex-basis: 0;
            }
        }
    }

    &__radio {
        cursor: pointer;
        border: 1px solid var(--color-400);
        border-radius: 4px;
        padding: 24px;
        position: relative;
        transition: all 0.15s ease 0s;

        &:not(.address__radio)._active {
            border-color: var(--green-700);
            background-color: var(--green-300);
        }

        @media(max-width: $tabletSmall){
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 52px;
            text-align: center;
            padding: 6px;
        }

        span {
            font-weight: 400;
            font-size: 18px;
            font-family: 'Libertinus';
            color: var(--color-main);
            line-height: 28px;
            text-wrap: nowrap;

            @media(max-width: $tabletSmall){
                line-height: 18px;
            }
        }

        input {
            cursor: pointer;
            appearance: none;
            font: var(--green-main);
            width: 32px;
            height: 32px;
            border: 1px solid var(--color-500);
            border-radius: 50%;
            position: absolute;
            top: 16px;
            right: 16px;

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                border: 3px solid var(--color-main-reverse);
                border-radius: 50%;
                transition: all 0.15s ease-in-out 0s;
            }

            &:checked {
                background-color: var(--green-main);
                border-color: var(--green-main);

                &::before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
            @media(max-width: $tabletSmall){
                display: none;
            }
        }

        p {
            margin-top: 12px;
            @media(max-width: $tabletSmall){
                display: none;
            }
        }
    }
    
    &__box{
        width: 100%;
    }
    &__description {
        padding: 24px;
        background-color: var(--green-300);
        border: 1px solid var(--green-700);
        border-radius: 4px;
        display: none;
        flex-direction: column;
        gap: 8px;
        transition: all 0.1s ease-in-out 0s;
        &._active{
            display: flex;
        }

        @media(max-width: $mobile){
            gap: 0;
            padding: 20px 12px;
        }

        p {
            position: relative;
            color: var(--color-main);
            padding-left: 24px;
           
            i {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                color: var(--green-main);

                @media(max-width: $mobileSmall){
                    top: 0;
                    transform: translateY(0);
                }
            }

            span {
                color: var(--color-600);
            }
        }

        > span{
            display: block;
            margin: 8px 0;
            font-family: 'Libertinus';
            line-height: 29px;
            font-size: 24px;
            font-weight: 400;
            color: var(--color-main);
            @media(max-width: $mobile){
                line-height: 23px;
            }
        }

        &:last-of-type{
            gap: 4px;
            @media(max-width: $mobile){
                gap: 0;
            }
        }
    }

    &__box {
        button {
            margin-top: 24px;
            width: 100%;
            justify-content: center;
            @media(max-width: $mobile){
                margin-top: 16px;
            }
        }
    }
    &__inner{
        display: flex;
        gap: 16px;
        @media(max-width: $pc){
            flex-direction: column;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        @media(max-width: $mobile){
            gap: 0;
        }
        .registration__checkbox{
            text-wrap: balance;
            align-items: flex-start;
            svg{
                top: 16px;
            }
        }
    }

    &__tippy{
        margin: 0 4px;
        color: var(--green-main);
        display: inline-block !important;
    }
}
.address__radio {
    border: none;
    padding: 6px;

    input {
        width: 16px;
        height: 16px;
        position: relative;
        top: unset;
        right: unset;

        &:checked {
            background-color: var(--color-main);
            border-color: var(--color-main);
        }
    }
}