@import "src/assets/fonts/fonts";
@import "root";
@import "reset";
@import "variables";
@import "size";


body{
  background: var(--bg-image) no-repeat center top / 100% auto;
  background-color: var(--bg-main);

  transition: 
    background-color var(--switch-theme-time),
    color var(--switch-theme-time);


  @media (max-width: $mobileSmall) {
    background: var(--bg-image-mobile) no-repeat center top / contain;
    background-color: var(--bg-main);
  }  

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: var(--color-200);
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--color-500);
  }
}

.lock {
  scrollbar-gutter: stable;
}



// Оболочка wrapper
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  

  @media (max-width: $mobile) {
    padding-bottom: 60px;
  }

  @supports (overflow: clip) {
    overflow: clip;
  }

  /* Прижимаем footer */
  > main {
    flex: 1 1 auto;
    padding-top: 183px;

    

    @media (max-width: $tablet) {
      padding-top: 140px;
    }
    @media (max-width: $mobile) {
      padding-top: 104px;
    }
    @media (max-width: $mobileExtraSmall) {
      padding-top: 94px;
    }
   
  }

  /* Фикс для слайдеров */
  > * {
    min-width: 0;
  }
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  /* Скролл заблокирован */
  .lock & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }
}

$containerPadding: 32px;
// Ширина ограничивающего контейнера по макету
$maxWidthContainer: 1440px;
// Ограничивающий контейнер
[class*="__container"] {
  margin: 0 auto;
  max-width: calc($maxWidthContainer + $containerPadding);
  padding-left: calc($containerPadding / 2);
  padding-right: calc($containerPadding / 2);

  @media (max-width: $mobileExtraSmall) {
    padding-left: 6px;
    padding-right: 6px;
  }
}

[class*="_disabled"]{
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.container {
  margin: 0 auto 16px;
  max-width: calc($maxWidthContainer + $containerPadding);
  padding-left: calc($containerPadding / 2);
  padding-right: calc($containerPadding / 2);
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobileExtraSmall) {
    padding-left: calc($containerPadding / 4);
    padding-right: calc($containerPadding / 4);
    gap: 12px;
  }
}

h1,
.title_h1,
h2,
.title_h2,
h3,
.title_h3,
h4,
.title_h4,
.title {
  color: var(--color-main);
  transition: color var(--switch-theme-time);
  font-weight: 400;
  font-family: "Libertinus";
  display: block;
  @media (max-width: $mobileSmall) {
    font-weight: 600;
  }

  &.currency--erocoin,
  & > .currency--erocoin {
    &::after{
      font-size: 90%;
    }
  }
}

h1,
.title_h1 {
  font-size: 42px;
  line-height: 46px;
  @media (max-width: $mobileSmall) {
    font-size: 30px;
    line-height: 33px;
  }
}

h1 {
  margin: 20px 0;
}

h2,
.title_h2 {
  font-size: 24px;
  line-height: 31px;
  @media (max-width: $mobileSmall) {
    font-size: 24px;
    line-height: 31px;
  }
}

h2 {
  margin: 16px 0 12px;
}

h3,
.title_h3 {
  font-family: "Libertinus";
  font-size: 18px;
  line-height: 23.4px;
  @media (max-width: $mobileSmall) {
    font-size: 18px;
    line-height: 23.4px;
    margin-bottom: 16px;
  }
}

h3 {
  margin: 16px 0 12px;
}


.p,.p1,.p2,.p3{
  color: var(--color-700);
}

p {
  margin: 6px 0;
  font-size: 15px;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.p1 {
  font-size: 15px;
  @media (max-width: $mobileSmall) {
    font-size: 14px;
  }
}

.p2 {
  font-size: 13px;
  @media (max-width: $mobileSmall) {
    font-size: 12px;
  }
}

.p3 {
  font-size: 12px;
  @media (max-width: $mobileSmall) {
    font-size: 10px;
  }
}

ul {
  list-style: none;
  margin: 6px 0 12px 4px;
  li {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      left: -20px;
      top: 4px;
      aspect-ratio: 1/1;
      width: 16px;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      background-color: var(--color-main);
      -webkit-mask-image: url("/assets/img/icons/check.svg");
      mask-image: url("/assets/img/icons/check.svg");
    }
  }
}

fieldset{
  border-radius: 4px;
  border: 1px solid var(--color-400);
  padding: 0px 12px ;
  padding-bottom: 6px;
  margin: 0;
  min-height: 50px;
  max-height: 50px;
  flex: 1;
  

  legend{
    font-size: 13px;
    padding: 0 4px;
    color: var(--green-secondary);
    text-wrap: nowrap;
  }  

  span.p1{
    position: relative;
    top: 2px;
  }

  &:has(textarea){
    min-height: 50px;
    max-height: min-content;
  }

  .input-label:not(.input-label--date){
    overflow: hidden;
  }

  .input-label{
    margin-left: 5px;
    padding: 0px;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;

    @media (max-width: $mobile) {
      margin-top: 2px;
    }

    &:hover, &:focus, &:active, &:focus-within{
      background-color: transparent;
    }

    input{
      &:hover, &:focus, &:active{
        background-color: transparent;
      }
    }
  }

  .select{
    width: 100%;
    height: 100%;
    .select__button{
      position: relative;
      top: -6px;
      padding: 0px;
      margin: 0px;
      max-height: 1em;
      background-color: transparent !important;
      border: none !important;

      i{
        right: -6px;
      }

      @media (max-width: $mobile) {
        top: -4px;
      }
    }
  }
}

ul {
  li {
    padding: 2px 0;
    margin: 4px 0 4px 24px;
  }
}

ol {
  li {
    margin: 8px 0 8px 24px;
  }
}

b {
  font-weight: 600;
}

a {
  color: var(--color-800);
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  text-decoration: underline;
  transition: color 0.15s ease 0s;
  &:hover {
    text-decoration: none;
    color: var(--color-main);

    .hover-line{
      text-underline-offset: 4px;
      text-decoration-thickness: 1px;
      text-decoration: underline;
    }
  }
  &:focus-within {
    text-decoration: none;
    color: var(--color-main);
  }
  &.green {
    color: var(--green-main);
    &:hover,
    &:focus-within {
      color: var(--color-main);
    }
  }

  &.green-reverse {
    color: var(--green-main);
    text-decoration: none;
    &:hover,
    &:focus-within {
      text-decoration: underline;
    }
  }

  &.disabled {
    cursor: default;
    color: var(--color-400);
    pointer-events: none;
  }
  &.hover-line:hover{
    span{
      text-decoration: underline;
    }
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}

.hover {
  text-decoration: none;

  &:hover,
  &:focus-within {
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration: underline;
  }
}

._online,
._offline {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid var(--white-main);
    border-radius: 50%;
  }
}

._online:after {
  background-color: var(--green-main);
}

._offline:after {
  background-color: var(--color-400);
}

// Сетка для карточек
.grid-cards {
  display: grid;
  grid-gap: 32px 18px;
  grid-template-columns: repeat(4, 1fr);
  height: fit-content;
  min-height: 545px;
  @media (max-width: $pc) {
    grid-gap: 32px 8px;
  }
  @media (max-width: $tablet) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $tabletSmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobileSmall) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px 0;
  }
}

// .model__name {
//   font-size: 32px;
//   font-family: "Libertinus";
//   font-weight: 400;
//   line-height: 115%;
//   padding-left: 18px;
//   @media (max-width: $mobile) {
//     font-size: 28px;
//   }
// }

.text-dots {
  // Ограничивается высота строки и обрезается лишнее с добавлением точек ("и так далее...")
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: normal;
  word-break: break-all;
}

.td-none {
  text-decoration: none;
}

.td-underline {
  text-decoration: underline;
}

.text-underline {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--color-main);
  }
}

.underline {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--color-main);
  }

  &:hover {
    &:before {
      opacity: 0;
    }
  }
}

.hover-line {
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus-within {
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration: underline !important;
  }
}


.img-cover {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.img-contain {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.required {
  &::after {
    position: absolute;
    content: "*";
    top: 0px;
    right: 0px;
    color: var(--green-main);
    font-size: 15px;
    pointer-events: none;
    user-select: none;
  }
}

.error-page__container {
  font-size: 20px;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.loader__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.loader__block {
  border: 1px solid var(--black-light);
  border-radius: 12px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 60px;
}

.other-page__container {
  padding-bottom: 60px;
  h1 + h2 {
    margin-top: 16px;
  }
  h2 {
    margin-top: 36px;
  }
}

.input-price {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  font-size: 15px;
  cursor: text;
  color: transparent;
  overflow: hidden;
  align-items: center;
  &:not(.coin):not(.czk):after {
    content: "€";
    margin-left: 4px;
    line-height: inherit;
  }
  
  
  &.czk::after {
    margin-left: 4px;
    content: "Kč";
    line-height: inherit;
  }
  &.currency--erocoin:after{
    margin-left: 0;
    margin-top: -4px;
  }
  &:has(input:placeholder-shown){
    &:not(.coin):not(.czk):after {
      margin-left: 30px;
      line-height: inherit;
    }

    &.czk::after{
      margin-left: 50px;
    }

    &.coin::after{
      margin-left: 25px;
    }
  }
  input {
    outline: none;
    background-color: transparent;
    position: absolute;
  }
}

.fake-checkbox {
  color: var(--green-main);
  display: flex;
  align-items: center;
  gap: 16px;
  i {
    background-color: var(--green-400);
  }
}

.button__close {
  color: var(--color-main);
  transform: rotate(0);
  transition: all 0.2s ease;
  @media (max-width: $mobile) {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover,
  &:focus-within{
    transform: rotate(20deg);
  }
}

input{
  font-size: 15px;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}
