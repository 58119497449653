@import "./src/styles/variables.scss";

.health {
  &.control {
    display: flex;
    border-radius: 4px;
    padding: 24px;
    flex-direction: column;
    background-color: var(--color-200);
    width: 100%;
    height: 100%;

    @media (max-width: $mobileSmall) {
      padding: 16px;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 16px;

    @media (max-width: $mobile) {
      grid-template-columns: none;
      grid-template-rows: 1fr auto;
      gap: 0;
    }
  }

  &__column-disc {
    display: flex;
    flex-direction: column;

    @media (max-width: $tabletSmall) {
      margin-top: 16px;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    color: var(--color-main);
    font-size: 18px;
    line-height: 23px;

    @media (max-width: $mobileSmall) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__health {
    display: flex;
    flex-direction: column;
  }

  &__photo {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    @media (max-width: $mobileExtraSmall) {
      flex-direction: column;
    }
  }

  &__examplePhoto {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background-color: var(--color-200);
    border-radius: 24px;
  }

  &__waiting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    padding: 24px;
    height: 200px;
    background-color: var(--green-200);
  }
}

.test {
  background-color: var(--color-200);
  width: 100%;
  height: 196px;
  border-radius: 24px;
  border: 1px dashed;
}
