@import "src/styles/variables";

.about {
  &__container {
    margin-top: 53px;
    position: relative;
    margin-bottom: 84px;
  }

  &__content{
    display: flex;
    justify-content: space-between;
    padding: 80px 74px;
    border-radius: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (min-width: $tablet) {
      background-image: var(--about-block);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &::before{
        border-radius: 24px;
        position: absolute;
        width: 500px;
        height: 100%;
        z-index: 0;
        top: 0;
        left: -1px;
        content: '';
        background: linear-gradient(271.24deg, transparent 5.21%, var(--color-main-reverse) 98.72%);
      }
      
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      padding: 12px 6px;
    }
  }

  &__description {
    z-index: 1;
    max-width: 494px;
    padding-top: 12px;
    margin-bottom: 44px;
    @media (max-width: $tablet) {
      padding: 0;
      margin: 30px;
    }

    .title_h1 {
      max-width: 300px;
      margin-bottom: 44px;

      span {
        color: var(--green-main);
      }
    }

    p {
      max-width: 434px;

      span {
        color: var(--green-main);
      }
    }
  }

  &__undertitle {
    margin-bottom: 6px;
    display: block;
    font-family: "Libertinus";
    font-size: 24px;
  }

  &__slogan,
  &__item {
    background: linear-gradient(
      110deg,
      rgba(28, 161, 130, 0.17) 0%,
      rgba(28, 161, 130, 0) 100%
    );
    border-radius: 4px;
    display: flex;
    color: var(--color-main);
  }

  &__slogan {
    z-index: 1;
    align-items: flex-end;
    max-width: 494px;
    padding: 12px 24px 24px 18px;
    gap: 30px;
    height: max-content;
    @media (min-width: $tablet) {
      grid-column: 1/2;
      grid-row: 2/3;
      margin-bottom: 28px;
    }
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 4px;
      align-items: center;
      text-align: center;
      max-width: 100%;
    }

    .logo-img {
      max-width: 132px;
      @media (max-width: $mobile) {
        max-width: 190px;
      }
    }

    span {
      font-size: 24px;
      line-height: 130%;
      display: block;
      color: var(--color-main);
      font-family: "Libertinus";
      font-weight: 400;

      @media (max-width: $mobile) {
        font-size: 18px;
      }
    }
  }

  &__benefits {
    max-width: 648px;
    padding: 31px 0;
    width: 100%;
    position: relative;
    @media (min-width: $tablet) {
      grid-row: 1/3;
    }
    @media (max-width: $pc) and (min-width: $tablet) {
      max-width: 570px;
    }
    @media (max-width: $tablet) {
      padding: 0;
      margin-bottom: 24px;
    }

    &:after {
      position: absolute;
      top: 0;
      transform: translateX(-100%);
      z-index: -1;
      content: "";
      width: 182px;
      height: 616px;
      background: url("/assets/img/about.webp") no-repeat center left / contain;
      .light & {
        background: url("/assets/img/about-light.webp") no-repeat center left /
          contain;
      }

      @media (max-width: $pc) {
        transform: translateX(-50%);
      }
      @media (max-width: $tablet) {
        right: 0;
        top: -30px;
        transform: translateX(0);
      }
      @media (max-width: $mobileExtraSmall) {
        top: 0;
      }
    }

    .title {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 125%;
    }
  }

  &__item {
    align-items: center;
    gap: 12px;
    padding: 12px;
    width: 100%;
   

    &:not(&:last-of-type) {
      margin-bottom: 12px;
    }

    i {
      font-size: 20px;
      background-color: var(--green-300);
    }
  }
}
