@import "./src/styles/variables.scss";

.statistic-page {
  &__container {
    padding-bottom: 120px;
  }
  &__header {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: $tablet) {
      flex-direction: column;
      gap: 32px;
    }
  }
  &__info-block {
    display: grid;
    grid-template-columns: 1.7fr repeat(2, 1fr);
    grid-column-gap: 16px;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    h1 {
      margin: 0;
    }
  }

  &__search {
    display: flex;
    gap: 16px;

    span {
      line-height: 43px;
    }
    span:hover {
      color: var(--color-main);
      cursor: pointer;
    }

    .dropdown {
      background-color: var(--color-200);
      border-radius: 4px;
      height: fit-content;
      .button {
        flex-wrap: nowrap;
        font-size: 15px;
        padding: 10px;
        @media (max-width: $mobile) {
          font-size: 13px;
        }
      }

      &:last-child{
        .dropdown__body{
          @media (max-width: $mobile) {
            transform: translateX(-120px);
        }
       
      }}
      .dropdown__body{
        
        .input-label{
          outline: none;
        }
        .rdr-caption .rdr-nav_button{
            border-radius: 4px;
          }
        
      }
    }

    .search, .search__label{
      border-radius: 4px;
    }
    
  }

  &__balance-stat {
    display: flex;
    gap: 16px;
  }

  &__items {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 16px;
    @media (max-width: $tablet) {
      grid-template-rows: repeat(6, 1fr);
    }
  }
  &__item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 8px;

    &-numbers {
      display: grid;
      grid-template-columns: 1fr 50px;
      gap: 4px;
      align-items: flex-end;
      justify-items: end;
    }
  }
}

.info-block-wrapper {
  grid-area: 1 / 1 / 2 / 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 20px 0;


  @media (max-width: $tablet) {
    display: flex;
    flex-direction: row;
    gap: 8px;


    .statistic-numbers-container__block{
      flex-grow: 0.5;
      flex-basis: 45%;
    }
  }
}

.info-block-wrapper.adCard {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.statistic-numbers-container {
  gap: 12px;
  color: var(--color-main);
  max-width: 100%;
  min-width: 50%;

  &__block {
    display: block;
  }

  &__item2, &__item1{
    display: grid;
    gap: 8px;
    align-items: center;
    padding: 8px 12px;
    font-size: 15px;
    height: max-content;
    background-color: var(--color-200);
    max-height: 40px;
    border-radius: 4px;

    @media (max-width: $mobile) {
        font-size: 14px;
    }

    @media (max-width: $mobileExtraSmall) {
        min-height: 40px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 4px;
      
  
      i {
        font-size: 1.2em;
      }
  
      span {
        color: var(--color-600);
        text-wrap: nowrap;
      }
    }

    span.numbers {
        text-align: end;
        line-height: 1;
    }

    span{
        color: var(--color-main);
    }

    
  }

  &__item2 {
    margin-bottom: 8px;
  }
  

  &__item1 {
    margin-bottom: 8px;
    
    span{
      text-align: end;
    }
  }

  @media (max-width: $mobileSmall) {
    &__item1, &__item2{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: unset;
      height: 65px;
      div{
        width: 100%;
      }
    }
  }


  &__item1.adcard {
    width: 100%;
    font-size: 15px;

    @media (max-width: $mobile) {
      font-size: 13px;
    }

  }
  
}

.balance-page__updown {
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 12px;
  align-items: center;
  // gap: 8px;
  border-radius: 4px;

  div{
    display: flex;
    align-items: center;
    gap: 8px;
    span{
      font-size: 15px;
      @media (max-width: $mobile) {
        font-size: 14px;
      }
    }
  }

  span.currency--erocoin{
    font-size: 18px;
  }

  &.up {
    background-color: var(--green-300);
    justify-content: space-between;
    @media (max-width: $mobile) {
      width: 100%;
      padding: 0px 12px;  
    }
  }

  &.down {
    background-color: var(--red-300);
    justify-content: space-between;
    @media (max-width: $mobile) {
      width: 100%;
      padding: 0px 12px;  
    }
  }

  @media (max-width: $mobileSmall) {
    padding: 12px 4px;
  }
}




.statistics-model-preview-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  grid-area: 1 / 2 / 2 / 4;

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__block {
    min-width: 50%;
    gap: 12px;
    display: flex;
    flex-direction: column;

    .buttons {
    display: flex;
      flex-direction: column;
      gap: 8px;
      .button {
        text-align: start;
        justify-content: space-between;
    }
  }
  }.dropdown

  .girlcard {
    background-color: var(--color-200);
    border-radius: 24px;
    padding: 8px;
    height: fit-content;

    .steps__progressbar {
      padding: 8px 12px;
      margin-top: 8px;
    }
  }
}

a.statistics-model-preview-container__block{
    gap: 8px;
    padding: 12px;
    text-decoration: none;
    
    img{
      height: 150px;
      width: fit-content;
      border-radius: 4px;
    }
    span{
      align-self: start;
      
    }
}

.statistics-block {
  a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    text-align: center;
    margin-top: 20px;
  }
}

.stat-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  .statistic-numbers-container {
    &__block {
      display: block;
    }
    &__item {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      div {
        color: var(--color-600);
      }
      @media (max-width: $mobileExtraSmall) {
        min-height: 52px;
      }

      span {
        font-size: 13px;
        color: var(--color-main);

        @media (min-width: $mobile) {
          font-size: 12px;
        }
      }

      div {
        display: flex;
        align-items: center;
        gap: 8px;
        @media (max-width: $mobile) {
          gap: 4px;
        }

        i {
          font-size: 1.2em;
        }
      }
    }
    
  }

  .balance-page__updown {
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    @media (max-width: $mobile) {
      font-size: 12px;
    }

    div {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
